export enum IntegrationType {
  GOOGLE_SPREADSHEET = 'GOOGLE_SPREADSHEET',
}

export enum DataSourceEnum {
  TABLE = 'TABLE',
  QUERY = 'QUERY',
}

export type Integration = {
  id: string;
  dataSourceType: DataSourceEnum;
  sqlDataSource: string;
  alias?: string;
  dataSource: string;
  type: IntegrationType;
  sheetName: string;
  spreadsheetId: string;
  scheduleExpression?: string;
  uuid: string;
  cronTime: string;
  cronDefinition: string;
  cronDay: string;
};

export const integrationTypeLabels: Record<IntegrationType, string> = {
  [IntegrationType.GOOGLE_SPREADSHEET]: 'Google spreadsheet',
};

export const dataSourceTypeLabels: Record<DataSourceEnum, string> = {
  [DataSourceEnum.TABLE]: 'Table',
  [DataSourceEnum.QUERY]: 'Query',
};
