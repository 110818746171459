import { useMutation } from 'react-query';
import { useCallback, useState } from 'react';
import Axios from 'axios';

import { importGitRepository } from 'app/repositories/api/importGitRepository';
import { useRepositoryIntegrationContext } from 'app/repositories/context/repository-integration';

export const useImportRepository = (projectId: string, repositoryId: string) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { provider, accessToken, refreshToken, selectedWorkspace, expiryAt } = useRepositoryIntegrationContext();
  const { mutate, isLoading, isSuccess, isError } = useMutation(importGitRepository, {
    onError: (error) => {
      if (Axios.isAxiosError(error) && error.response?.status === 409) {
        setErrorMessage('Repository is already imported');
      } else {
        setErrorMessage('Cannot import repository');
      }
    },
  });

  const onClick = useCallback(() => {
    if (isSuccess || isError) return;

    mutate({
      accessToken,
      provider,
      refreshToken,
      repositoryId,
      workspaceId: selectedWorkspace,
      expiryAt,
      projectId,
    });
  }, [accessToken, refreshToken, provider, repositoryId, selectedWorkspace, isSuccess, isError, expiryAt, projectId]);

  return { isLoading, isSuccess, isError, onClick, errorMessage };
};
