import { Popconfirm, Button } from 'antd';
import { useQueryClient } from 'react-query';

import { apiClient } from '../../api/client/apiClient';

import { SdiIntegration } from './types';
import { SDI_INTEGRATIONS_API_URL, SDI_INTEGRATIONS_QUERY } from './consts';

interface DeleteIntegrationProps {
  integration: SdiIntegration;
}

export const DeleteIntegration = ({ integration }: DeleteIntegrationProps) => {
  const queryClient = useQueryClient();

  const onConfirm = async () => {
    await apiClient.delete(`${SDI_INTEGRATIONS_API_URL}/sdi-integrations/${integration.id}`);
    await queryClient.invalidateQueries(SDI_INTEGRATIONS_QUERY);
  };

  return (
    <Popconfirm title="Are you sure to delete this integration?" onConfirm={onConfirm} okText="Yes" cancelText="No">
      <Button danger>Delete</Button>
    </Popconfirm>
  );
};
