import { AccessTableDTO } from 'app/applications/types';

import { AccessTableRowDataType } from '../types';

export const mapAccessToPickerOptions = (data: AccessTableDTO[]): AccessTableRowDataType[] =>
  data.map((item) => ({
    name: item.table,
    key: item.table,
    type: 'TABLE',
    children: item.columns.map((column) => ({
      name: column,
      type: 'COLUMN',
      key: `${item.table}.${column}`,
    })),
  }));
