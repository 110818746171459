import { apiClient } from 'api/client/apiClient';
import { API_URLS } from '../consts';

interface RemoveProjectPayload {
  projectId: string;
}

export const recreateProjectData = async ({ projectId }: RemoveProjectPayload) => {
  await apiClient.post<void>(`${API_URLS.ProjectManagementSystem}/recreate-project-data/${projectId}`);
};
