import { Popconfirm, Button } from 'antd';
import { useQueryClient } from 'react-query';

import { apiClient } from '../../api/client/apiClient';

import { WORKFLOW_API_URL, WORKFLOW_QUERY } from './consts';
import { RunWorkflowProps } from './RunWorkflow.types';

export const RunWorkflow = ({ stateMachineArn }: RunWorkflowProps) => {
  const queryClient = useQueryClient();

  const onConfirm = async () => {
    await apiClient.post(`${WORKFLOW_API_URL}/workflows`, { stateMachineArn });
    await queryClient.invalidateQueries(WORKFLOW_QUERY);
  };

  return (
    <Popconfirm title="Are you sure to run this workflow?" onConfirm={onConfirm} okText="Yes" cancelText="No">
      <Button>Run</Button>
    </Popconfirm>
  );
};
