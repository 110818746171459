import { AxiosError, AxiosResponse } from 'axios';

import { AppRoute } from '../../../routing/AppRoute.enum';

export const responseSuccessInterceptor = (response: AxiosResponse) => response;

export const responseFailureInterceptor = async (error: AxiosError) => {
  if (error.response?.status === 401) {
    window.location.href = AppRoute.logout;
  }
  return Promise.reject(error);
};
