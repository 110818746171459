import { ReactQueryDevtools } from 'react-query/devtools';

import { Layout } from 'app/layout/Layout';

import { AppProps } from './App.types';

export const App = ({ openReactQueryDevtools }: AppProps) => {
  return (
    <>
      <Layout />
      {openReactQueryDevtools && <ReactQueryDevtools initialIsOpen={false} />}
    </>
  );
};
