import { getScheduleExpression } from '../../shared/get-schedule-expression.util';

import { DataInterface, SdiColumnType, SdiIntegration, SdiIntegrationId } from './types';

export const parsePostData = (data: DataInterface): SdiIntegration => {
  const result: Pick<SdiIntegration, 'payload'> & Partial<SdiIntegration> = {
    id: data.id as SdiIntegrationId,
    payload: {
      googleSheets: {
        id: data.spreadsheetId,
        tab: data.sheetName,
        range: data.range,
        pageSize: data.pageSize,
      },
      db: {
        tableName: data.dbTableName,
      },
      mapping: data.mapping.map((mapping) => {
        const type: SdiColumnType = mapping.targetFieldType;

        return {
          source: {
            columnName: mapping.sourceColumnName,
          },
          target: {
            dbFieldName: mapping.targetFieldName,
            type,
          },
        };
      }),
    },
  };

  const scheduleExpression = getScheduleExpression(data);
  if (scheduleExpression) {
    result.scheduleExpression = scheduleExpression;
  }

  return result;
};
