import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';
import { ProjectDTO } from 'app/project-management/types';

import { recreateProjectData } from 'app/project-management/api';

export const useRecreateProjectData = (project: ProjectDTO) => {
  const { mutate: recreateProject, isLoading } = useMutation(recreateProjectData, {
    onSuccess: () => {
      notification.success({
        message: `The workflow to recreate project data has been started. It could take a few minutes to complete that.`,
      });
    },
    onError: (error: AxiosError) => {
      notification.error({ message: error.message || 'Cannot trigger workflow to recreate project data' });
    },
  });

  const onClick = useCallback(() => {
    recreateProject({ projectId: project.id });
  }, [recreateProject]);

  return { onClick, isLoading };
};
