import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { TableColumnProps, notification } from 'antd';

import { ProjectDTO } from '../../types';
import { LIST_PROJECTS_QUERY_KEY } from '../../consts';
import { fetchProjects } from 'app/project-management/api';
import { RecreateProjectData } from 'app/project-management/components/recreate-project-data';
import { RemoveProjectIntegration } from 'app/project-management/components/remove-project-integration';

const useProjectListColumns = (): TableColumnProps<ProjectDTO>[] =>
  useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Provider',
        dataIndex: 'system',
        key: 'provider',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render: (_: string, record: ProjectDTO) => {
          return (
            <div className="actions">
              <RecreateProjectData project={record} />
              <RemoveProjectIntegration project={record} />
            </div>
          );
        },
      },
    ],
    [],
  );

export const useProjectList = () => {
  const columns = useProjectListColumns();

  const { data, isLoading } = useQuery(LIST_PROJECTS_QUERY_KEY, fetchProjects, {
    onError: () => {
      notification.error({ message: 'Cannot fetch projects list!' });
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    dataSource: data,
    loading: isLoading,
    columns,
  };
};
