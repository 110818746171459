import { List } from 'antd';

import { useRepositoryIntegrationContext } from 'app/repositories/context/repository-integration';
import { GitRepositoryItem } from '../git-repository-item';

export const GitRepositories = () => {
  const { loadingRepositories, repositories } = useRepositoryIntegrationContext();

  return <List loading={loadingRepositories} dataSource={repositories} renderItem={GitRepositoryItem} />;
};
