import { ProjectDTO } from 'app/project-management/types';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { LIST_PROJECTS_QUERY_KEY } from 'app/project-management/consts';
import { removeProject } from 'app/project-management/api';

export const useRemoveProjectIntegration = (project: ProjectDTO) => {
  const queryClient = useQueryClient();

  const { mutate: callRemoveProject, isLoading } = useMutation(removeProject, {
    onSuccess: () => {
      queryClient.invalidateQueries(LIST_PROJECTS_QUERY_KEY);
      notification.success({ message: `Project ${project.name} has been unattached successfully.` });
    },
    onError: (error: AxiosError) => {
      notification.error({ message: error.message || 'Cannot to unwatch project' });
    },
  });

  const onClick = useCallback(() => {
    callRemoveProject({ projectId: project.id });
  }, [project, callRemoveProject]);

  return { onClick, isLoading };
};
