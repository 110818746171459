import decode from 'jwt-decode';
import { Navigate, Outlet } from 'react-router-dom';

import { AppRoute } from '../../../routing/AppRoute.enum';
import { useAuth } from 'hooks/useAuth/useAuth';

type Token = {
  groups: string[];
};

export const PermissionController = () => {
  const { accessToken } = useAuth();
  if (accessToken) {
    const tokenData = decode<Token>(accessToken);
    const userGroups = tokenData.groups;
    const requiredGroup = process.env.REACT_APP_AZURE_ACCESS_GROUP ?? '';
    if (userGroups.indexOf(requiredGroup) > -1) {
      return <Outlet />;
    }
  }
  return <Navigate to={AppRoute.forbidden} />;
};
