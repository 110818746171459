import { Select } from 'antd';
import { useMemo } from 'react';

import { useRepositoryIntegrationContext } from 'app/repositories/context/repository-integration';

export const WorkspaceSelector = () => {
  const { loadingWorkspaces, workspaces, selectedWorkspace, onSelectWorkspace } = useRepositoryIntegrationContext();

  const options = useMemo(() => workspaces.map((item) => ({ label: item.name, value: item.uuid })), [workspaces]);

  return (
    <Select
      loading={loadingWorkspaces}
      onSelect={onSelectWorkspace}
      value={selectedWorkspace}
      style={{ maxWidth: '300px', width: '100%' }}
      placeholder="Select workspace"
      {...{ options }}
    />
  );
};
