import axios, { AxiosError } from 'axios';
import { Button, Col, Form, Modal, notification, PageHeader, Row, Spin, Upload, UploadProps } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { read } from 'xlsx';
import { RcFile } from 'antd/lib/upload';

import { ApiError } from '../../api/client/types';
import { apiClient } from 'api/client/apiClient';

import { FormField } from './FileIntegration.types';
import { FileIntegrationUploadProps, PresingedData, UploadData } from './UploadFile.types';
import { FILE_INTEGRATIONS_API_URL } from './consts';

export const FileIntegrationUpload = ({ fileIntegration }: FileIntegrationUploadProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const props: UploadProps = {
    onRemove: () => setFileList([]),
    beforeUpload: async (file) => {
      await checkFile(file);
      return false;
    },
    accept: '.xlsx',
    maxCount: 1,
    fileList,
  };

  const checkFile = async (file: RcFile): Promise<void> => {
    const arr = await file.arrayBuffer();
    try {
      read(arr, { WTF: true });
      setFileList([file]);
    } catch {
      notification.error({
        message: 'Upload',
        description: 'File type is incorrect',
      });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields().then(async (data: UploadData) => {
      setIsLoading(true);
      try {
        const presignedData = await getPresignedData(fileIntegration[FormField.integrationName]);
        const uploadResponse = await uploadFile(data, presignedData);
        if (uploadResponse.status === 204) {
          notification.open({
            message: 'Upload',
            description: 'File has been successfully uploaded',
          });
          setIsModalVisible(false);
        }
      } catch (error) {
        let description = '';
        if (axios.isAxiosError(error)) {
          description = (error as AxiosError<ApiError>).response?.data.description ?? error.toString();
        } else {
          description = (error as Error).message;
        }
        notification.error({
          message: 'Upload',
          description,
        });
      } finally {
        setIsLoading(false);
      }
    });
  };

  const getPresignedData = async (integrationName: string): Promise<PresingedData> => {
    const response = await apiClient.get(`${FILE_INTEGRATIONS_API_URL}/file-integrations/create-post-url`, {
      params: { integrationName },
    });
    return response.data;
  };

  const uploadFile = async (data: UploadData, presignedData: PresingedData) => {
    const { file } = data.file;
    const formData = new FormData();
    Object.entries(presignedData.presignedPost.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append(
      'key',
      `file-integrations-raw/${fileIntegration[FormField.integrationName]}/${presignedData.creationDate}/snapshot.xlsx`,
    );
    formData.append('file', file);

    return await axios.post(presignedData.presignedPost.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button onClick={showModal}>Upload file</Button>
      <Modal title="Upload file" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText={'Upload'}>
        <Row>
          <Col span={12}>
            <PageHeader title="Upload XLSX file" />
          </Col>
        </Row>
        <div className="content-body">
          <Row>
            <Col span={12}>
              <Spin spinning={isLoading}>
                <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 12 }} autoComplete="off" form={form}>
                  <Form.Item label="File" name="file" rules={[{ required: true, message: 'Please upload file' }]}>
                    <Upload {...props}>
                      <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                  </Form.Item>
                </Form>
              </Spin>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
