import { Row, Col, PageHeader, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { AddNewProjectButton } from 'app/project-management/components/add-new-project-button';

import { useProjectList } from './hooks';

export const ProjectManagementSystemsList = () => {
  const { dataSource, loading, columns } = useProjectList();
  return (
    <Content>
      <Row>
        <Col span={12}>
          <PageHeader title="Project management systems list" />
        </Col>
        <Col span={12} className="add-configuration">
          <AddNewProjectButton />
        </Col>
      </Row>

      <div className="content-body">
        <Table
          {...{ dataSource, loading, columns }}
          rowKey="id"
          size="middle"
          pagination={{
            pageSize: 20,
          }}
        />
      </div>
    </Content>
  );
};
