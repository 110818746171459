import { Button, Form, Input, Popconfirm, Row, Typography } from 'antd';

import './application-header.css';
import { useApplicationDetails } from 'app/applications/context/application-details';

import { useApplicationHeaderActions } from './hooks';

export const ApplicationDetailsHeader = () => {
  const { application, deleteApplication } = useApplicationDetails();
  const { form, cancelEdit, editMode, isUpdating, onSubmit, toggleEditMode } = useApplicationHeaderActions();

  if (editMode) {
    return (
      <Form {...{ form }} onFinish={onSubmit} initialValues={{ name: application.name }}>
        <Form.Item name="name" style={{ marginBottom: '0.5rem' }}>
          <Input />
        </Form.Item>
        <Button loading={isUpdating} type="primary" htmlType="submit">
          Save
        </Button>
        <Button disabled={isUpdating} onClick={cancelEdit}>
          Cancel
        </Button>
      </Form>
    );
  }

  return (
    <Row>
      <Typography.Title style={{ marginBottom: 0 }}>{application?.name}</Typography.Title>
      <div className="application-header-actions">
        <Button onClick={toggleEditMode} type="primary" ghost>
          Edit
        </Button>
        <Popconfirm
          title="Do you want to delete application?"
          onConfirm={deleteApplication}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      </div>
    </Row>
  );
};
