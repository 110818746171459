import { RepositoryDTO } from 'app/repositories/types';
import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { REPOSITORIES_QUERY_KEY } from 'app/repositories/consts';
import { removeGitRepository } from 'app/repositories/api/removeGitRepository';

export const useRemoveRepositoryIntegration = (repository: RepositoryDTO) => {
  const queryClient = useQueryClient();

  const { mutate: removeRepo, isLoading } = useMutation(removeGitRepository, {
    onSuccess: () => {
      queryClient.invalidateQueries(REPOSITORIES_QUERY_KEY);
      notification.success({ message: `Repository ${repository.name} has been disconnected successfully.` });
    },
    onError: (error: AxiosError) => {
      notification.error({ message: error.message || 'Cannot disconnect repository' });
    },
  });

  const onClick = useCallback(() => {
    removeRepo({ repositoryId: repository.id });
  }, [repository, removeRepo]);

  return { onClick, available: !!repository.importedBy, isLoading };
};
