import { cronDefinition } from './schedule-expression-consts';

export const getCronFromScheduleExpression = (scheduleExpression?: string) => {
  if (!scheduleExpression) {
    return {
      cronDefinition: '',
      cronTime: undefined,
      cronDay: undefined,
    };
  }

  if (scheduleExpression.startsWith('cron(')) {
    return {
      cronDefinition: 'custom',
      cronTime: undefined,
      cronDay: undefined,
    };
  }

  if (scheduleExpression.startsWith('rate(')) {
    if (cronDefinition.find((cron) => cron.value === scheduleExpression)) {
      return {
        cronDefinition: scheduleExpression,
        cronTime: undefined,
        cronDay: undefined,
      };
    }
  }

  return {
    cronDefinition: 'custom',
    cronTime: undefined,
    cronDay: undefined,
  };
};
