import { useForm, useWatch } from 'antd/lib/form/Form';
import { useCallback, useMemo } from 'react';
import isEqual from 'lodash.isequal';
import { useMutation } from 'react-query';

import { useApplicationDetails } from 'app/applications/context/application-details';
import { mapFormAccessToApplicationAccessDTO } from 'app/applications/utils/map-form-access-to-application-access-dto.util';
import { updateApplicationAccesses } from 'app/applications/api/updateApplicationAccesses';

import { UpdateApplicationAccessesFormValues } from './types';

export const useApplicationAccesses = () => {
  const [form] = useForm<UpdateApplicationAccessesFormValues>();
  const { invalidateApplicationData, application } = useApplicationDetails();
  const { mutateAsync: callUpdateAccesses, isLoading } = useMutation(updateApplicationAccesses, {
    retry: false,
    onSettled: () => {
      invalidateApplicationData();
    },
  });
  const initialValues = useMemo(
    () => ({ accesses: application.accesses.map((access) => `${access.table}.${access.column}`) || [] }),
    [application.accesses],
  );
  const newAccesses = useWatch('accesses', form) || initialValues.accesses;

  const hasChanged = useMemo(
    () => !isEqual(initialValues.accesses?.sort(), newAccesses?.sort()),
    [newAccesses, initialValues.accesses],
  );

  const onCancel = useCallback(() => {
    form.resetFields();
  }, [form]);

  const onUpdate = useCallback(
    (data: UpdateApplicationAccessesFormValues) => {
      const updatedAccesses = mapFormAccessToApplicationAccessDTO(data?.accesses || []);

      callUpdateAccesses({
        applicationId: application.id,
        accesses: updatedAccesses,
      });
    },
    [form, application, callUpdateAccesses],
  );

  return { form, isLoading, initialValues, hasChanged, onCancel, onUpdate };
};
