import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import {
  useAccount,
  useIsAuthenticated,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';

import { useAuth } from 'hooks/useAuth/useAuth';

import { AppRoute } from './AppRoute.enum';

export const AuthorizedRoute = () => {
  const location = useLocation();
  const { accessToken } = useAuth();
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { login } = useAuth();
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  useEffect(() => {
    if (!accessToken && inProgress !== InteractionType.None && inProgress !== InteractionStatus.Startup) {
      setIsAuthenticating(true);
    }
    if (account && inProgress === InteractionType.None) {
      (async () => {
        const res = await instance.acquireTokenSilent({
          scopes: [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`],
          account: account,
        });

        if (res) {
          login(res.accessToken);
        }
      })().finally(() => setIsAuthenticating(false));
    }
  }, [account, instance, inProgress, login, accounts]);

  if (isAuthenticating) return <Spin spinning />;

  if (inProgress !== InteractionType.None || (!accessToken && isAuthenticated)) {
    return <Spin spinning />;
  }

  if (accessToken) {
    const decoded = jwtDecode(accessToken as string) as { exp: number };
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime >= decoded.exp) {
      return <Spin spinning />;
    }
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <Navigate to={AppRoute.login} state={{ from: location }} replace />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Outlet />
      </AuthenticatedTemplate>
    </>
  );
};
