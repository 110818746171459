export enum PayrollType {
  UD = 'UD',
  UZ = 'UZ',
  UOP = 'UOP',
  POW = 'POW',
}

export type UploadData = {
  year: number;
  month: number;
  type: PayrollType;
  file: {
    file: File;
  };
};
