import { apiClient } from 'api/client/apiClient';
import { API_URLS } from '../consts';

interface RemoveProjectPayload {
  projectId: string;
}

export const removeProject = async ({ projectId }: RemoveProjectPayload) => {
  const result = await apiClient.delete<boolean>(`${API_URLS.ProjectManagementSystem}/remove-project/${projectId}`);

  return result.data;
};
