import { Content } from 'antd/lib/layout/layout';
import { Col, Row, Space } from 'antd';

import { RepositoryIntegrationProvider } from 'app/repositories/context/repository-integration';

import { WorkspaceSelector } from './components/workspace-selector';
import { GitRepositories } from './components/git-repositories';
import { AddRepositoryIntegrationTitle } from './components/add-repository-integration-title';
import { GitRepositorySearch } from './components/git-repository-search';

export const AddRepositoryIntegration = () => {
  return (
    <RepositoryIntegrationProvider>
      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          padding: 48,
        }}
      >
        <Space direction="vertical" size="large">
          <AddRepositoryIntegrationTitle />
          <Row>
            <Col style={{ gap: 16, display: 'flex' }} md={12}>
              <WorkspaceSelector />
              <GitRepositorySearch />
            </Col>
          </Row>
          <GitRepositories />
        </Space>
      </Content>
    </RepositoryIntegrationProvider>
  );
};
