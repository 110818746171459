import { Select } from 'antd';
import { useMemo } from 'react';

import { useProjectManagementContext } from 'app/project-management/context/project-management-integration';

export const ResourceSelector = () => {
  const { loadingAccessibleResources, accessibleResources, selectedResource, onSelectResource } =
    useProjectManagementContext();

  const options = useMemo(
    () => accessibleResources.map((item) => ({ label: item.name, value: item.id })),
    [accessibleResources],
  );

  return (
    <Select
      loading={loadingAccessibleResources}
      onSelect={onSelectResource}
      value={selectedResource}
      style={{ maxWidth: '300px', width: '100%' }}
      placeholder="Select workspace"
      {...{ options }}
    />
  );
};
