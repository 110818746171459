import { useState } from 'react';
import { Button, Modal, Form, Input, Select, Alert, Spin, Space, InputNumber } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { ApiError } from '../../api/client/types';
import { apiClient } from '../../api/client/apiClient';

import { FILE_INTEGRATIONS_QUERY, FILE_INTEGRATIONS_API_URL } from './consts';
import { FileIntegration, FormField } from './AddFileIntegration.types';

export const AddFileIntegration = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [apiError, setApiError] = useState<ApiError>();

  const columnTypes = ['string', 'number', 'date'];

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (data: FileIntegration) => {
      await apiClient.post(`${FILE_INTEGRATIONS_API_URL}/file-integrations`, data);
    },
    {
      onSuccess: async () => {
        form.resetFields();
        setIsModalVisible(false);
        await queryClient.invalidateQueries(FILE_INTEGRATIONS_QUERY);
      },
      onError: (error: AxiosError<ApiError>) => {
        if ([400, 409].includes(error.response?.data.statusCode as number)) {
          setApiError(error.response?.data);
        }
      },
    },
  );

  const showModal = () => {
    setApiError(undefined);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields().then((data: FileIntegration) => {
      mutation.mutate(data);
      form.resetFields();
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add file integration
      </Button>
      <Modal
        title="Add file integration"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Add'}
        width={530}
      >
        <Spin spinning={mutation.isLoading}>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            autoComplete="off"
            initialValues={{ firstRowIndex: 1 }}
            form={form}
          >
            {apiError && <Alert message={apiError.description} type="error" className="form-errors" />}
            <Form.Item
              label="Integration name"
              name={FormField.integrationName}
              rules={[
                { required: true, message: 'Please input integration name' },
                {
                  min: 3,
                  max: 255,
                  message: 'Integration name must be between 3 and 255 characters',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.List name="columns">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} style={{ display: 'inline-flex', marginBottom: 8 }} size="small" align="baseline">
                      <Form.Item
                        {...restField}
                        label="Column name"
                        name={[name, FormField.columnName]}
                        rules={[{ required: true, message: 'Please input column name' }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Column type"
                        name={[name, FormField.columnType]}
                        rules={[{ required: true, message: 'Please input column type' }]}
                      >
                        <Select
                          options={columnTypes.map((columnType) => ({ label: columnType, value: columnType }))}
                          placeholder="Select a column type"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Spreadsheet column"
                        name={[name, FormField.columnSpreadsheet]}
                        rules={[{ required: true, message: 'Please input spreadsheet column' }]}
                      >
                        <Input />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add column
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Form.Item
              label="First row index"
              name={FormField.firstRowIndex}
              rules={[{ required: true, message: 'Please input first row index' }]}
            >
              <InputNumber min={0} step={1} />
            </Form.Item>
            <Form.Item
              label="Sheet name"
              name={FormField.sheetName}
              rules={[
                { required: true, message: 'Please input sheet name!' },
                {
                  min: 3,
                  max: 255,
                  message: 'Sheet name must be between 3 and 255 characters',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
