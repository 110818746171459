import { AxiosResponse } from 'axios';

import { apiClient } from 'api/client/apiClient';
import { ProjectDTO } from '../types';
import { API_URLS } from '../consts';

interface ImportProjectRequestBody {
  provider: string;
  projectId: string;
  accessToken: string;
  refreshToken: string;
  resourceId: string;
  expiryAt: Date | null;
}

export const importProject = async (data: ImportProjectRequestBody) => {
  const result = await apiClient.post<ProjectDTO, AxiosResponse<ProjectDTO>, ImportProjectRequestBody>(
    `${API_URLS.ProjectManagementSystem}/import-project`,
    data,
  );

  return result.data;
};
