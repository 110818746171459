import { Button, Popconfirm, notification } from 'antd';

import { apiClient } from '../../api/client/apiClient';

import { SdiIntegration } from './types';
import { SDI_INTEGRATIONS_API_URL } from './consts';

interface DeleteIntegrationProps {
  integration: SdiIntegration;
}

export const RunIntegration = ({ integration }: DeleteIntegrationProps) => {
  const onConfirm = async () => {
    try {
      await apiClient.post(`${SDI_INTEGRATIONS_API_URL}/sdi-integrations/${integration.id}/run`, {});
      notification.open({
        message: 'Integration',
        description: 'Integration has been successfully run',
      });
    } catch (error) {
      let errorMessage = 'Unknown error';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      notification.error({
        message: 'Integration',
        description: errorMessage,
      });
    }
  };

  return (
    <Popconfirm title="Are you sure to run this integration?" onConfirm={onConfirm} okText="Yes" cancelText="No">
      <Button style={{ borderColor: 'green', color: 'green' }}>Run</Button>
    </Popconfirm>
  );
};
