export interface FileIntegration {
  [FormField.integrationName]: string;
  columns: Column[];
  [FormField.firstRowIndex]: number;
  [FormField.sheetName]: string;
}

interface Column {
  [FormField.columnName]: string;
  [FormField.columnType]: string;
  [FormField.columnSpreadsheet]: string;
}

export enum FormField {
  integrationName = 'integrationName',
  columnName = 'columnName',
  columnType = 'columnType',
  columnSpreadsheet = 'columnSpreadsheet',
  firstRowIndex = 'firstRowIndex',
  sheetName = 'sheetName',
}
