import { Button, DatePicker, Form, Modal, Tooltip } from 'antd';
import moment from 'moment';
import { RepositoryDTO } from 'app/repositories/types';

import { useRecreateGitRepoData } from './hooks';

interface RecreateGitRepoDataProps {
  repository: RepositoryDTO;
}

export const RecreateGitRepoData = ({ repository }: RecreateGitRepoDataProps) => {
  const { onClick, isLoading, isModalVisible, onCancel, onConfirm } = useRecreateGitRepoData(repository);

  return (
    <>
      <Tooltip title="It will trigger asynchronous job to recreate GIT data.">
        <Button type="primary" onClick={onClick} loading={isLoading}>
          Recreate repository data
        </Button>
      </Tooltip>
      <Modal
        onCancel={onCancel}
        okButtonProps={{
          form: 'recreate-git-data-form',
          htmlType: 'submit',
          loading: isLoading,
        }}
        visible={isModalVisible}
      >
        <Form
          initialValues={{ fromYear: moment().year() }}
          id="recreate-git-data-form"
          name="recreate-git-data-form"
          autoComplete="off"
          onFinish={onConfirm}
        >
          <Form.Item
            getValueFromEvent={(date: moment.Moment) => date?.year()}
            getValueProps={(year) => ({
              value: year ? moment().year(year) : undefined,
            })}
            label="Recreate from year"
            name="fromYear"
            rules={[{ required: true }]}
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <DatePicker
              disabledDate={(date) => {
                return date.year() > moment().year();
              }}
              picker="year"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
