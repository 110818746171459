import { Form, Modal } from 'antd';
import { useCallback, useState } from 'react';

import { mapFormAccessToApplicationAccessDTO } from '../../utils/map-form-access-to-application-access-dto.util';

import { CreateApplicationFormValues, CreateApplicationModalProps } from './types';
import { CreateApplicationFormLayout } from './form-layout';

export const CreateApplicationModal = ({ open, onSubmit, onCancel }: CreateApplicationModalProps) => {
  const [form] = Form.useForm<CreateApplicationFormValues>();
  const [isSubmitting, setSubmitting] = useState(false);

  const onFormSubmit = useCallback(
    async (data: CreateApplicationFormValues) => {
      if (!onSubmit) {
        return;
      }

      setSubmitting(true);

      onSubmit({
        name: data.name,
        accesses: mapFormAccessToApplicationAccessDTO(data?.accesses || []),
      }).finally(() => {
        setSubmitting(false);
      });
    },
    [onSubmit],
  );

  return (
    <Modal
      closable={!isSubmitting}
      title="Create a new application"
      visible={open}
      onOk={() => form.submit()}
      onCancel={onCancel}
      maskClosable={!isSubmitting}
      cancelButtonProps={{
        disabled: isSubmitting,
      }}
      confirmLoading={isSubmitting}
    >
      <Form {...{ form }} name="create-application" initialValues={{}} onFinish={onFormSubmit} autoComplete="off">
        <CreateApplicationFormLayout />
      </Form>
    </Modal>
  );
};
