import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import { useProjectManagementContext } from 'app/project-management/context/project-management-integration';

export const ProjectsSearch = () => {
  const { setSearchQuery } = useProjectManagementContext();

  return (
    <Input
      placeholder="Search..."
      prefix={<SearchOutlined />}
      allowClear
      style={{ maxWidth: '300px' }}
      onChange={(event) => setSearchQuery(event.target.value)}
    />
  );
};
