import { List } from 'antd';

import { useProjectManagementContext } from 'app/project-management/context/project-management-integration';
import { ProjectsItem } from '../projects-item';

export const Projects = () => {
  const { loadingProjects, projects } = useProjectManagementContext();

  return <List loading={loadingProjects} dataSource={projects} renderItem={ProjectsItem} />;
};
