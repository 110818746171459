import { Avatar } from 'antd';
import List from 'antd/lib/list';
import { GitRepositoryDTO } from 'app/repositories/types';

import { ImportRepository } from '../import-repository';

export const GitRepositoryItem = (item: GitRepositoryDTO) => (
  <List.Item
    actions={[
      <ImportRepository projectId={item.project.uuid} repositoryId={item.uuid} key={`${item.uuid}-import-btn`} />,
    ]}
  >
    <List.Item.Meta
      style={{ alignItems: 'center' }}
      avatar={item.avatarUrl ? <Avatar style={{ backgroundColor: 'gray' }} src={item.avatarUrl} /> : undefined}
      title={
        <a target="_blank" rel="noreferrer" href={item.gitUrl}>
          {item.name}
        </a>
      }
      description={item.project?.name || ''}
    />
  </List.Item>
);
