import * as moment from 'moment/moment';

import { ONCE_A_DAY } from './schedule-expression-consts';

export const getScheduleExpression = (data: {
  cronDefinition: string;
  cronTime: string;
  cronDay: string;
  scheduleExpression?: string;
}): string | undefined => {
  const cron = data.cronDefinition;

  if (cron?.length > 0) {
    if (cron === 'custom') {
      return data.scheduleExpression || undefined;
    }

    if (cron.startsWith('once_a')) {
      const time = data.cronTime as unknown as moment.Moment;
      if (cron === ONCE_A_DAY) {
        return `cron(${time.minutes()} ${time.hour()} * * ? *)`;
      } else {
        return `cron(${time.minutes()} ${time.hour()} ? * ${data['cronDay']} *)`;
      }
    } else {
      return cron;
    }
  }
};
