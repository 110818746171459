import Typography from 'antd/lib/typography';

import { useRepositoryIntegrationContext } from 'app/repositories/context/repository-integration';

export const AddRepositoryIntegrationTitle = () => {
  const { provider } = useRepositoryIntegrationContext();

  return (
    <>
      <Typography.Title style={{ marginBottom: '0.5rem' }}>Import GIT repository</Typography.Title>
      <Typography.Title style={{ marginTop: 0 }} level={4}>
        Provider: {provider}
      </Typography.Title>
    </>
  );
};
