import { Button, Tooltip } from 'antd';
import { useMemo } from 'react';
import { CheckCircleOutlined, ExclamationOutlined } from '@ant-design/icons';

import { useImportProject } from './hooks';

interface ImportProjectProps {
  projectId: string;
}

export const ImportProject = ({ projectId }: ImportProjectProps) => {
  const { onClick, isLoading, isSuccess, errorMessage, isError } = useImportProject(projectId);

  const message = useMemo(() => {
    if (isSuccess) {
      return 'Imported';
    }
    if (isLoading) {
      return 'Importing';
    }

    return 'Import';
  }, [isSuccess, isLoading]);

  const buttonIcon = useMemo(() => {
    if (isSuccess) {
      return <CheckCircleOutlined />;
    }

    if (isError) {
      return <ExclamationOutlined />;
    }

    return undefined;
  }, [isSuccess, isError]);

  return (
    <Tooltip title={errorMessage || ''}>
      <Button
        type="primary"
        danger={isError}
        icon={buttonIcon}
        disabled={isSuccess || isError}
        loading={isLoading}
        {...{ onClick }}
      >
        {message}
      </Button>
    </Tooltip>
  );
};
