/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useMemo, createContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Navigate, useNavigate } from 'react-router-dom';
import { Spin, notification } from 'antd';

import { APPLICATIONS_QUERY_KEY } from '../../consts';
import { getApplication } from '../../api/getApplication';
import { AppRoute } from 'routing/AppRoute.enum';
import { updateApplication as updateApplicationApi } from 'app/applications/api/updateApplication';
import { deleteApplication as deleteApplicationApi } from 'app/applications/api/deleteApplication';

import { ApplicationDetailsContextType, ApplicationDetailsProviderProps } from './types';

export const ApplicationDetailsContext = createContext<ApplicationDetailsContextType | undefined>(undefined);

export const ApplicationDetailsProvider = ({
  value,
  children,
}: React.ProviderProps<ApplicationDetailsProviderProps>) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const queryKey = useMemo(() => [APPLICATIONS_QUERY_KEY, value.applicationId], [value.applicationId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: application, isLoading } = useQuery(queryKey, ({ queryKey: [_, appId] }) => getApplication(appId), {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const invalidateApplicationData = useCallback(() => {
    queryClient.invalidateQueries(queryKey);
  }, [queryClient, queryKey]);

  const { mutateAsync: callUpdateApplication } = useMutation(updateApplicationApi, {
    retry: false,
    onSuccess: () => {
      invalidateApplicationData();
    },
    onError: () => {
      notification.error({ message: 'Cannot update application!' });
    },
  });

  const { mutateAsync: callDeleteApplication } = useMutation(deleteApplicationApi, {
    retry: false,
    onSuccess: () => {
      invalidateApplicationData();
      navigate(AppRoute.applications);
    },
    onError: () => {
      notification.error({ message: 'Cannot delete application!' });
    },
  });

  const updateApplicationName = useCallback(
    async (name: string) => {
      await callUpdateApplication({
        id: value.applicationId,
        name,
      });
    },
    [value.applicationId],
  );

  const deleteApplication = useCallback(async () => {
    await callDeleteApplication({
      id: value.applicationId,
    });
  }, [callDeleteApplication, value.applicationId]);

  const contextValue = useMemo(
    () => ({
      applicationId: value.applicationId,
      application,
      isLoading,
      invalidateApplicationData,
      updateApplicationName,
      deleteApplication,
    }),
    [value.applicationId, application, isLoading, invalidateApplicationData, updateApplicationName, deleteApplication],
  );

  if (contextValue.isLoading) {
    return <Spin spinning />;
  }

  if (!contextValue.application) {
    return <Navigate to={AppRoute.applications} />;
  }

  return (
    <ApplicationDetailsContext.Provider value={contextValue as ApplicationDetailsContextType}>
      {children}
    </ApplicationDetailsContext.Provider>
  );
};

export const useApplicationDetails = (): ApplicationDetailsContextType => {
  const context = useContext(ApplicationDetailsContext);

  if (context === undefined) {
    throw new Error('ApplicationDetailsContext must be within ApplicationDetailsProvider');
  }

  return context;
};
