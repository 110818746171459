import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { ApplicationApiKeyDTO } from 'app/applications/types';

import { useApplicationDetails } from 'app/applications/context/application-details';
import { deleteApplicationApiKey } from 'app/applications/api/deleteApplicationApiKey';

import { UseApplicationApiKeyDeletionReturnType } from './types';

export const useApplicationApiKeyDeletion = (apiKey: ApplicationApiKeyDTO): UseApplicationApiKeyDeletionReturnType => {
  const { invalidateApplicationData, applicationId } = useApplicationDetails();

  const { mutateAsync: callDeleteApiKey, isLoading } = useMutation(deleteApplicationApiKey, {
    retry: false,
    onSettled: () => {
      invalidateApplicationData();
    },
  });

  const deleteApiKey = useCallback(() => {
    callDeleteApiKey({
      apiKeyId: apiKey.id,
      applicationId,
    });
  }, [apiKey, applicationId]);

  return [deleteApiKey, { isLoading }];
};
