import './Login.css';

import { Button } from 'antd';
import { useMsal } from '@azure/msal-react';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { Navigate } from 'react-router-dom';

import tshLogo from 'assets/images/tsh.png';
import { AppRoute } from 'routing/AppRoute.enum';
import { useAuth } from 'hooks/useAuth/useAuth';

const initializeSignIn = (instance: IPublicClientApplication, accounts: AccountInfo[]) => {
  if (accounts.length === 0) {
    instance.loginRedirect();
  }
};

export const Login = () => {
  const { instance, accounts } = useMsal();
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={AppRoute.integrations} />;
  }

  return (
    <div className="login-page">
      <div className="login-form">
        <img className="login-logo" alt="tsh-logo" src={tshLogo} />
        <Button type="primary" className="login-button" onClick={() => initializeSignIn(instance, accounts)}>
          Login
        </Button>
      </div>
    </div>
  );
};
