import { useForm } from 'antd/lib/form/Form';
import { useState, useCallback } from 'react';

import { useApplicationDetails } from 'app/applications/context/application-details';

export const useApplicationHeaderActions = () => {
  const [form] = useForm();
  const { updateApplicationName } = useApplicationDetails();
  const [isEditMode, setEditMode] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const cancelEdit = useCallback(() => {
    form.resetFields();
    setEditMode(false);
  }, [form]);

  const onSubmit = useCallback(
    async ({ name }) => {
      setUpdating(true);
      try {
        await updateApplicationName(name);
        setEditMode(false);
      } finally {
        setUpdating(false);
      }
    },
    [updateApplicationName],
  );

  const toggleEditMode = useCallback(() => setEditMode(true), []);

  return { form, editMode: isEditMode, isUpdating, cancelEdit, onSubmit, toggleEditMode };
};
