import React, { useCallback } from 'react';
import { Avatar, Dropdown, Layout as AntLayout, Menu, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Link } from 'react-router-dom';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';

import { AppRoutes } from 'routing/AppRoutes';
import logo from 'assets/images/logo.svg';
import { useAuth } from '../../hooks/useAuth/useAuth';
import { AppRoute } from '../../routing/AppRoute.enum';
import 'antd/dist/antd.min.css';
import './Layout.css';

const { Header, Content } = AntLayout;

const menuItems: ItemType[] = [
  { label: <Link to={AppRoute.integrations}>Integrations</Link>, key: AppRoute.integrations },
  { label: <Link to={AppRoute.uploadInvoices}>Upload invoices</Link>, key: AppRoute.uploadInvoices },
  { label: <Link to={AppRoute.fileIntegrations}>File integrations</Link>, key: AppRoute.fileIntegrations },
  { label: <Link to={AppRoute.sdiIntegrations}>SDI integrations</Link>, key: AppRoute.sdiIntegrations },
  { label: <Link to={AppRoute.uploadPayrolls}>Upload payrolls</Link>, key: AppRoute.uploadPayrolls },
  { label: <Link to={AppRoute.workflows}>Workflows</Link>, key: AppRoute.workflows },
  { label: <Link to={AppRoute.applications}>Applications</Link>, key: AppRoute.applications },
  { label: <Link to={AppRoute.repositories}>Repositories</Link>, key: AppRoute.repositories },
  {
    label: <Link to={AppRoute.projectManagementSystems}>Project management</Link>,
    key: AppRoute.projectManagementSystems,
  },
];

export const Layout = () => {
  const { instance } = useMsal();
  const { isAuthenticated, user, logout } = useAuth();

  const onLogoutButtonClick = useCallback(() => {
    logout();
    instance.logoutRedirect();
  }, [instance, logout]);

  const profileMenu = () => (
    <Menu>
      <Menu.Divider />
      <Menu.Item>
        <div onClick={onLogoutButtonClick}>Logout</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <AntLayout>
      {isAuthenticated && (
        <Header className="header">
          <div className="logo">
            <img src={logo} alt="TSH" />
          </div>
          <Menu className="navbar-menu" theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={menuItems} />
          <div className="navbar-profile">
            <Dropdown overlay={profileMenu()}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Avatar icon={<UserOutlined />} />
                  {user?.username}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Header>
      )}
      <Content className="layout-content">
        <div className="site-layout-content">
          <AppRoutes />
        </div>
      </Content>
    </AntLayout>
  );
};
