import { Popconfirm, Button } from 'antd';
import { useQueryClient } from 'react-query';

import { apiClient } from '../../api/client/apiClient';

import { FILE_INTEGRATIONS_QUERY, FILE_INTEGRATIONS_API_URL } from './consts';
import { FileIntegration } from './FileIntegration.types';

interface DeleteFileIntegrationProps {
  fileIntegration: FileIntegration;
}

export const DeleteFileIntegration = ({ fileIntegration }: DeleteFileIntegrationProps) => {
  const queryClient = useQueryClient();

  const onConfirm = async () => {
    await apiClient.delete(`${FILE_INTEGRATIONS_API_URL}/file-integrations/${fileIntegration.id}`);
    await queryClient.invalidateQueries(FILE_INTEGRATIONS_QUERY);
  };

  return (
    <Popconfirm title="Are you sure to delete this integration?" onConfirm={onConfirm} okText="Yes" cancelText="No">
      <Button danger>Delete</Button>
    </Popconfirm>
  );
};
