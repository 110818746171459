import { useQuery } from 'react-query';
import { notification } from 'antd';
import { useCallback } from 'react';
import { SelectionSelectFn } from 'antd/lib/table/interface';

import { fetchAccessTables } from 'app/applications/api/fetchAccessTables';
import { TABLES_QUERY_KEY } from 'app/applications/consts';

import { mapAccessToPickerOptions } from './utils/map-access-to-picker-options.util';
import { AccessTableRowDataType, UseAccessPickerDataParams } from './types';

export const useAccessPickerData = ({ onChange }: UseAccessPickerDataParams) => {
  const { data = [], isLoading } = useQuery(TABLES_QUERY_KEY, fetchAccessTables, {
    onError: () => {
      notification.error({ message: 'Cannot fetch available tables!' });
    },
    select: mapAccessToPickerOptions,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const onSelect: SelectionSelectFn<AccessTableRowDataType> = useCallback((row, isSelected, selectedRows) => {
    const selectedColumns = (selectedRows || [])
      .filter((selectedRow) => selectedRow.type === 'COLUMN')
      .map((selectedRow) => selectedRow.key);

    onChange?.(selectedColumns);
  }, []);

  return { data, isLoading, onSelect };
};
