import { useParams, Navigate } from 'react-router-dom';
import { Divider } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { ApplicationDetailsProvider } from 'app/applications/context/application-details';
import { AppRoute } from 'routing/AppRoute.enum';

import { ApplicationDetailsHeader } from './components/header';
import { ApplicationDetailsParams } from './types';
import { ApplicationDetailsAccess } from './components/access';
import { ApplicationDetailsApiKeys } from './components/api-keys';

export const ApplicationDetails = () => {
  const { id: applicationId } = useParams<ApplicationDetailsParams>();

  if (!applicationId) {
    return <Navigate to={AppRoute.applications} />;
  }

  return (
    <ApplicationDetailsProvider value={{ applicationId }}>
      <Content style={{ padding: '1.5rem 3rem' }}>
        <ApplicationDetailsHeader />
        <Divider />
        <ApplicationDetailsAccess />
        <Divider />
        <ApplicationDetailsApiKeys />
      </Content>
    </ApplicationDetailsProvider>
  );
};
