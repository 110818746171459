import { useCallback } from 'react';
import { useMutation } from 'react-query';

import { useApplicationDetails } from 'app/applications/context/application-details';
import { createApplicationApiKey } from 'app/applications/api/createApplicationApiKey';

import { UseApplicationApiKeyCreationReturnType } from './types';

export const useApplicationApiKeyCreation = (): UseApplicationApiKeyCreationReturnType => {
  const { invalidateApplicationData, applicationId } = useApplicationDetails();
  const { mutateAsync: callCreateApiKey, isLoading } = useMutation(createApplicationApiKey, {
    retry: false,
    onSettled: () => {
      invalidateApplicationData();
    },
  });

  const createApiKey = useCallback(() => {
    callCreateApiKey({
      applicationId,
    });
  }, [applicationId]);

  return [createApiKey, { isLoading }];
};
