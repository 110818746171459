import { Popconfirm, Button } from 'antd';

import { useApplicationApiKeyCreation } from './hooks';

export const CreateApplicationApiKeyConfirmButton = () => {
  const [createApiKey, { isLoading }] = useApplicationApiKeyCreation();

  return (
    <Popconfirm title="Do you want to generate a new API key?" onConfirm={createApiKey} okText="Yes" cancelText="No">
      <Button loading={isLoading} type="primary">
        Create API key
      </Button>
    </Popconfirm>
  );
};
