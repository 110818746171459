import { Col, Row, Table, Typography } from 'antd';

import { CreateApplicationApiKeyConfirmButton } from '../create-application-api-key/confirm-button';
import { useApplicationDetails } from 'app/applications/context/application-details';

import { applicationApiKeysTableColumns } from './consts/table-columns.const';

export const ApplicationDetailsApiKeys = () => {
  const { application, isLoading } = useApplicationDetails();

  return (
    <>
      <Row>
        <Col span={12}>
          <Typography.Title level={3}>API keys</Typography.Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <CreateApplicationApiKeyConfirmButton />
        </Col>
      </Row>
      <Table loading={isLoading} dataSource={application.apiKeys || []} columns={applicationApiKeysTableColumns} />
    </>
  );
};
