import { apiClient } from 'api/client/apiClient';
import { API_URLS } from '../consts';

interface RemoveGitRepositoryPayload {
  repositoryId: string;
  fromYear?: number;
}

export const recreateGitRepositoryData = async ({ repositoryId, fromYear }: RemoveGitRepositoryPayload) => {
  await apiClient.post<void>(`${API_URLS.Repositories}/recreate-git-repo-data/${repositoryId}`, {
    fromYear,
  });
};
