import { apiClient } from 'api/client/apiClient';
import { API_URLS } from '../consts';

export interface ProviderAvailability {
  provider: string;
  isAvailable: boolean;
}

export const fetchAvailableProviders = async () => {
  const result = await apiClient.get<ProviderAvailability[]>(`${API_URLS.Repositories}/available-providers`);

  return result.data;
};
