import axios, { AxiosError } from 'axios';
import { Button, Col, Form, InputNumber, notification, PageHeader, Row, Spin, Upload, UploadProps } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { read } from 'xlsx';
import { RcFile } from 'antd/lib/upload';

import { ApiError } from '../../api/client/types';
import { apiClient } from '../../api/client/apiClient';

import { PresignedPost, UploadData } from './types';

export const UploadInvoices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();

  const props: UploadProps = {
    onRemove: () => setFileList([]),
    beforeUpload: async (file) => {
      await checkFile(file);
      return false;
    },
    accept: '.xlsx',
    maxCount: 1,
    fileList,
  };

  const onFinish = () => {
    form.validateFields().then(async (data: UploadData) => {
      setIsLoading(true);
      try {
        const presignedData = await getPresignedData(data.year);
        const uploadResponse = await uploadFile(data, presignedData);
        if (uploadResponse.status === 204) {
          notification.open({
            message: 'Upload',
            description: 'File has been successfully uploaded',
          });
          resetForm();
        }
      } catch (error) {
        let description = '';
        if (axios.isAxiosError(error)) {
          description = (error as AxiosError<ApiError>).response?.data.description ?? error.toString();
        } else {
          description = (error as Error).message;
        }
        notification.error({
          message: 'Upload',
          description,
        });
      } finally {
        setIsLoading(false);
      }
    });
  };

  const checkFile = async (file: RcFile): Promise<void> => {
    const arr = await file.arrayBuffer();
    try {
      read(arr, { WTF: true });
      setFileList([file]);
    } catch (e) {
      notification.error({
        message: 'Upload',
        description: 'File type is incorrect',
      });
    }
  };

  const uploadFile = async (data: UploadData, presignedPost: PresignedPost) => {
    const file = data.file.file;
    const formData = new FormData();
    Object.entries(presignedPost.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('key', `saldeo-invoice-raw/${data.year}/snapshot.xlsx`);
    formData.append('file', file);

    const response = await axios.post(presignedPost.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response;
  };

  const getPresignedData = async (year: number): Promise<PresignedPost> => {
    const response = await apiClient.get(
      `${process.env.REACT_APP_FINANCE_SERVICE_URL}/saldeo/create-post-invoice-url`,
      { params: { year } },
    );
    return response.data;
  };

  const resetForm = () => {
    form.setFieldsValue({ year: new Date().getFullYear() });
  };

  useEffect(() => {
    resetForm();
  }, []);

  return (
    <>
      <Row>
        <Col span={12}>
          <PageHeader title="Upload invoices XLS file" />
        </Col>
      </Row>
      <div className="content-body">
        <Row>
          <Col span={12}>
            <Spin spinning={isLoading}>
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
                form={form}
                onFinish={onFinish}
              >
                <Form.Item label="Year" name="year" rules={[{ required: true, message: 'Please type year' }]}>
                  <InputNumber
                    min={'1970'}
                    step={1}
                    formatter={(val?: string) => (val ? parseInt(val, 10).toFixed(0).toString() : '')}
                  />
                </Form.Item>
                <Form.Item label="File" name="file" rules={[{ required: true, message: 'Please upload file' }]}>
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Upload
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Col>
        </Row>
      </div>
    </>
  );
};
