import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';

export const useUser = () => {
  const { accounts } = useMsal();
  const user = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();

  return {
    user,
    isAuthenticated,
  };
};
