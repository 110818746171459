import { useEffect, useState } from 'react';
import { Alert, Button, Form, Input, InputNumber, Modal, Select, Spin, TimePicker } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import * as moment from 'moment';

import { ApiError } from '../../api/client/types';
import { apiClient } from '../../api/client/apiClient';
import { getCronFromScheduleExpression } from '../../shared/get-cron-from-schedule-expression.util';
import { CRON_DAYS, cronDefinition, CUSTOM, ONCE_A_WEEK } from '../../shared/schedule-expression-consts';

import { DataInterface, SdiIntegration } from './types';
import { SDI_INTEGRATIONS_API_URL, SDI_INTEGRATIONS_QUERY } from './consts';
import { MappingForm } from './MappingForm';
import { parsePostData } from './parse-post-data.util';

interface EditIntegrationProps {
  integration?: SdiIntegration;
}

export const AddIntegration = ({ integration }: EditIntegrationProps) => {
  const initialCronDefinition = getCronFromScheduleExpression(integration?.scheduleExpression);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [apiError, setApiError] = useState<ApiError>();
  const [showCronTime, setShowCronTime] = useState<boolean>(false);
  const [showCronDay, setShowCronDay] = useState<boolean>(false);
  const [showCronCustom, setShowCronCustom] = useState<boolean>(false);

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
      setShowCronTime(false);
      setShowCronDay(false);
      setShowCronCustom(initialCronDefinition?.cronDefinition === CUSTOM);
    }
  }, [isModalVisible]);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (postData: DataInterface) => {
      setIsLoading(true);

      if (integration) {
        return apiClient.patch(
          `${SDI_INTEGRATIONS_API_URL}/sdi-integrations/${integration.id}`,
          parsePostData(postData),
        );
      } else {
        return apiClient.post(`${SDI_INTEGRATIONS_API_URL}/sdi-integrations`, parsePostData(postData));
      }
    },
    {
      onSuccess: async () => {
        form.resetFields();
        setIsModalVisible(false);
        await queryClient.invalidateQueries(SDI_INTEGRATIONS_QUERY);
      },
      onError: (error: AxiosError<ApiError>) => {
        if ([400, 409, 500].includes(error.response?.status as number)) {
          setApiError(error.response?.data);
        }
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );

  const showModal = () => {
    setApiError(undefined);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields().then((data: DataInterface) => {
      mutation.mutate(data);
    });
  };

  const onChangeCronDefinition = (value: string) => {
    setShowCronTime(value.startsWith('once_a'));
    setShowCronDay(value === ONCE_A_WEEK);
    setShowCronCustom(value === CUSTOM);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const cronTime = initialCronDefinition?.cronTime
    ? moment.default(initialCronDefinition?.cronTime, 'HH:mm')
    : undefined;

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {integration ? 'Edit' : 'Add'} integration
      </Button>
      <Modal
        title={(integration ? 'Edit' : 'Add') + ' integration'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={integration ? 'Save' : 'Add'}
      >
        <Spin spinning={isLoading}>
          <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" form={form}>
            {apiError && <Alert message={apiError.description} type="error" className="form-errors" />}

            <Form.Item
              label="Spreadsheet Id"
              name="spreadsheetId"
              initialValue={integration?.payload.googleSheets.id}
              rules={[
                { required: true, message: 'Please input your ID!' },
                {
                  min: 3,
                  max: 255,
                  message: 'Spreadsheet Id must be between 3 and 255 characters',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Sheet name"
              name="sheetName"
              initialValue={integration?.payload.googleSheets.tab}
              rules={[
                { required: true, message: 'Please input your Sheet name' },
                {
                  min: 3,
                  max: 255,
                  message: 'Sheet name must be between 3 and 255 characters',
                },
              ]}
            >
              <Input placeholder={'e.g. PEOPLE'} />
            </Form.Item>

            <Form.Item
              label="Range"
              name="range"
              initialValue={integration?.payload.googleSheets.range}
              rules={[
                { required: true, message: 'Please input your range' },
                {
                  min: 3,
                  max: 255,
                  message: 'Range must be between 3 and 255 characters',
                },
              ]}
            >
              <Input placeholder="e.g. A1:Z" />
            </Form.Item>

            <Form.Item
              label="Page size"
              name="pageSize"
              initialValue={integration?.payload.googleSheets.pageSize}
              rules={[
                { required: true, message: 'Please input your page size' },
                {
                  type: 'number',
                  min: 1,
                  message: 'Page size must be minimum 1',
                },
              ]}
            >
              <InputNumber placeholder="10000" />
            </Form.Item>

            <Form.Item
              label="Target table name"
              name="dbTableName"
              initialValue={integration?.payload.db.tableName}
              rules={[
                { required: true, message: 'Please input your target table name' },
                {
                  min: 3,
                  max: 255,
                  message: 'Target table name must be between 3 and 255 characters',
                },
                {
                  pattern: /^[a-z0-9_]+$/,
                  message: "Use only lowercase letters, numbers and '_'",
                },
              ]}
            >
              <Input placeholder="e.g. people" />
            </Form.Item>

            <MappingForm initialMapping={integration?.payload.mapping} />

            <Form.Item label="Schedule" name="cronDefinition" initialValue={initialCronDefinition?.cronDefinition}>
              <Select options={cronDefinition} onChange={onChangeCronDefinition} />
            </Form.Item>

            <div style={showCronTime ? { display: 'inline' } : { display: 'none' }}>
              <Form.Item
                name="cronTime"
                label="Select schedule time"
                initialValue={cronTime}
                rules={[{ required: showCronTime, message: 'Please select cron time' }]}
              >
                <TimePicker format="HH:mm" />
              </Form.Item>
            </div>
            <div style={showCronDay ? { display: 'inline' } : { display: 'none' }}>
              <Form.Item
                label="Select schedule day"
                name="cronDay"
                initialValue={initialCronDefinition?.cronDay}
                rules={[{ required: showCronDay, message: 'Please select cron day' }]}
              >
                <Select options={CRON_DAYS.map((d) => ({ label: d, value: d }))} />
              </Form.Item>
            </div>
            <div style={showCronCustom ? { display: 'inline' } : { display: 'none' }}>
              <Form.Item
                label="Custom expression"
                name="scheduleExpression"
                initialValue={integration?.scheduleExpression}
                rules={[{ required: showCronCustom, message: 'Please provide custom cron expression' }]}
              >
                <Input placeholder="e.g. cron(0 0 1 * ? *)" />
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
