import Axios from 'axios';

import { requestSuccessInterceptor } from '../../hooks/useAxiosStrategy/interceptors/requestInterceptors';
import {
  responseFailureInterceptor,
  responseSuccessInterceptor,
} from '../../hooks/useAxiosStrategy/interceptors/responseInterceptors';

export const apiClient = Axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});
apiClient.interceptors.request.use(requestSuccessInterceptor);
apiClient.interceptors.response.use(responseSuccessInterceptor, responseFailureInterceptor);
