import { Storage } from './AuthStorage.types';

const ACCESS_TOKEN_KEY = 'accessToken';

class AuthStorage {
  private _accessToken: string | null = null;
  private _storage: Storage | null = null;

  constructor(_storage: Storage) {
    try {
      this._storage = _storage;
      this.accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
    } catch (error) {
      this._storage = null;
      this.accessToken = null;
    }
  }

  get accessToken(): string | null {
    return this._accessToken;
  }

  set accessToken(value: string | null) {
    this._accessToken = value;

    try {
      if (typeof value === 'string') {
        this._storage?.setItem(ACCESS_TOKEN_KEY, value);
      } else {
        this._storage?.removeItem(ACCESS_TOKEN_KEY);
      }
    } catch (error) {
      this._storage?.onError(error);
    }
  }
}

const storage: Storage = {
  getItem: (key: string) => sessionStorage.getItem(key),
  setItem: (key: string, value: string) => sessionStorage.setItem(key, value),
  removeItem: (key: string) => sessionStorage.removeItem(key),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onError: (error: unknown) => {
    // handle errors here
  },
};

export const authStorage = new AuthStorage(storage);
