import React from 'react';
import 'assets/styles/main.css';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { AppProviders } from 'providers/AppProviders';
import { msalConfig } from 'authConfig';

import * as serviceWorker from './serviceWorker';
import { App } from './App';

const openReactQueryDevtools = process.env.NODE_ENV === 'development';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

const container = document.getElementById('root');
const root = createRoot(container as Element);
const instance = new PublicClientApplication(msalConfig);

root.render(
  <MsalProvider instance={instance}>
    <AppProviders>
      <App openReactQueryDevtools={openReactQueryDevtools} />
    </AppProviders>
  </MsalProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
