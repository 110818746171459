import { PageHeader, Table, Row, Col, Input } from 'antd';
import { useQuery } from 'react-query';
import { ChangeEvent, useState } from 'react';

import { apiClient } from '../../api/client/apiClient';

import { AddIntegration } from './AddIntegration';
import { SDI_INTEGRATIONS_API_URL, SDI_INTEGRATIONS_QUERY } from './consts';
import { DeleteIntegration } from './DeleteIntegration';
import { RunIntegration } from './RunIntegration';
import { RunHistory } from './RunHistory';
import './integration.css';
import { SdiIntegration } from './types';

const columns = [
  { title: 'Table name', dataIndex: ['payload', 'db', 'tableName'], key: 'tableName' },
  { title: 'Sheet name', dataIndex: ['payload', 'googleSheets', 'tab'], key: 'sheetName' },
  {
    title: 'Sheet ID',
    dataIndex: ['payload', 'googleSheets', 'id'],
    key: 'spreadsheetId',
    render: (id: string) => (
      <a href={`https://docs.google.com/spreadsheets/d/${id}`} target="_blank" rel="noopener noreferrer">
        {id}
      </a>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_: string, record: SdiIntegration) => {
      return (
        <div className="actions">
          <RunIntegration integration={record} />
          <AddIntegration integration={record} />
          <DeleteIntegration integration={record} />
          <RunHistory integration={record} />
        </div>
      );
    },
  },
];

export const SdiIntegrations = () => {
  const query = useQuery<SdiIntegration[]>(SDI_INTEGRATIONS_QUERY, async () => {
    const result = await apiClient.get(`${SDI_INTEGRATIONS_API_URL}/sdi-integrations`);
    return result.data;
  });

  const [tableFilter, setTableFilter] = useState({
    tableName: '',
    sheetName: '',
    spreadsheetId: '',
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTableFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const filteredData = query.data?.filter((integration) => {
    return (
      integration.payload.db.tableName.toLowerCase().includes(tableFilter.tableName.toLowerCase()) &&
      integration.payload.googleSheets.tab.toLowerCase().includes(tableFilter.sheetName.toLowerCase()) &&
      integration.payload.googleSheets.id.toLowerCase().includes(tableFilter.spreadsheetId.toLowerCase())
    );
  });

  return (
    <>
      <Row>
        <Col span={12}>
          <PageHeader title="Spreadsheet to Database Import integration configuration" />
        </Col>
        <Col span={12} className="add-configuration">
          <AddIntegration />
        </Col>
      </Row>

      <div className="content-body">
        <Row gutter={16} className="filter-row" style={{ marginBottom: '1rem' }}>
          <Col span={8}>
            <Input
              placeholder="Filter by table name"
              name="tableName"
              value={tableFilter.tableName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Filter by sheet name"
              name="sheetName"
              value={tableFilter.sheetName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Filter by sheet ID"
              name="spreadsheetId"
              value={tableFilter.spreadsheetId}
              onChange={handleFilterChange}
            />
          </Col>
        </Row>

        <Table
          loading={query.isLoading}
          dataSource={filteredData}
          columns={columns}
          rowKey="id"
          size="middle"
          pagination={false}
        />
      </div>
    </>
  );
};
