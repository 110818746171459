export enum AppRoute {
  integrations = '/integrations',
  uploadInvoices = '/upload-invoices',
  uploadPayrolls = '/upload-payrolls',
  fileIntegrations = '/file-integrations',
  sdiIntegrations = '/sdi-integrations',
  workflows = '/workflows',
  login = '/login',
  logout = '/logout',
  forbidden = '/forbidden',
  keycloak = '/keycloak',
  applications = '/applications',
  applicationDetails = '/applications/:id',
  repositories = '/repositories',
  repositoriesAddIntegration = '/repositories/new',
  projectManagementSystems = '/project-management-systems',
  addAccessibleResourceIntegration = '/project-management-systems/new',
}
