import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import { useMemo } from 'react';
import Menu from 'antd/lib/menu';

import { useAddNewProjectButton } from './hooks';

export const AddNewProjectButton = () => {
  const { providers, isLoading, onClick } = useAddNewProjectButton();

  const menu = useMemo(
    () => (
      <Menu
        items={providers.map((provider) => ({
          key: provider,
          label: provider.toUpperCase(),
          onClick: () => onClick(provider),
        }))}
      ></Menu>
    ),
    [providers, onClick],
  );

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Button loading={isLoading}>Add a new project</Button>
    </Dropdown>
  );
};
