import { Form, Input } from 'antd';

import { AccessPicker } from '../access-picker';

export const CreateApplicationFormLayout = () => (
  <>
    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Application name is required' }]}>
      <Input />
    </Form.Item>
    <Form.Item name="accesses">
      <AccessPicker title={() => 'Accesses'} />
    </Form.Item>
  </>
);
