import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { TableColumnProps, notification } from 'antd';

import { RepositoryDTO } from '../../types';
import { REPOSITORIES_QUERY_KEY } from '../../consts';
import { fetchRepositories } from '../../api/fetchRepositories';
import { RemoveRepositoryIntegration } from 'app/repositories/components/remove-repository-integration';
import { RecreateGitRepoData } from 'app/repositories/components/recreate-git-repo-data';

const useRepositoriesListColumns = (): TableColumnProps<RepositoryDTO>[] =>
  useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Project',
        dataIndex: 'projectName',
        key: 'projectName',
      },
      {
        title: 'Provider',
        dataIndex: 'provider',
        key: 'provider',
      },
      {
        title: 'Imported by',
        dataIndex: 'importedBy',
        key: 'importedBy',
        render: (importedBy: string) => (importedBy ? importedBy : 'MANUALLY ADDED'),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (_: string, record: RepositoryDTO) => {
          return (
            <div className="actions">
              <RemoveRepositoryIntegration repository={record} />
              <RecreateGitRepoData repository={record} />
            </div>
          );
        },
      },
    ],
    [],
  );

export const useRepositoriesList = () => {
  const columns = useRepositoriesListColumns();

  const { data, isLoading } = useQuery(REPOSITORIES_QUERY_KEY, fetchRepositories, {
    onError: () => {
      notification.error({ message: 'Cannot fetch repositories list!' });
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    dataSource: data,
    loading: isLoading,
    columns,
  };
};
