import { ApplicationAccessTableDTO } from 'app/applications/types';

export const mapFormAccessToApplicationAccessDTO = (accesses: string[]): ApplicationAccessTableDTO[] =>
  accesses.reduce((prev, tableColumnKey) => {
    const [table, column] = tableColumnKey.split('.');

    return [
      ...prev,
      {
        table,
        column,
        read: true,
      },
    ];
  }, [] as ApplicationAccessTableDTO[]);
