import { TableColumnProps } from 'antd';
import { ApplicationApiKeyDTO } from 'app/applications/types';

import { DeleteApplicationApiKeyConfirmButton } from '../../delete-application-api-key/confirm-button';

export const applicationApiKeysTableColumns: TableColumnProps<ApplicationApiKeyDTO>[] = [
  {
    title: 'API Key',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_: string, record) => (
      <div className="actions">
        <DeleteApplicationApiKeyConfirmButton apiKey={record} />
      </div>
    ),
  },
];
