import { apiClient } from 'api/client/apiClient';
import { API_URLS } from '../consts';

interface RemoveGitRepositoryPayload {
  repositoryId: string;
}

export const removeGitRepository = async ({ repositoryId }: RemoveGitRepositoryPayload) => {
  const result = await apiClient.delete<boolean>(`${API_URLS.Repositories}/remove-git-repo/${repositoryId}`);

  return result.data;
};
