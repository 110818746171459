import { AxiosResponse } from 'axios';

import { apiClient } from 'api/client/apiClient';
import { CreateApplicationRequestBody } from '../types';
import { API_URLS } from '../consts';

export const createApplication = async (data: CreateApplicationRequestBody) => {
  const result = await apiClient.post<void, AxiosResponse<void>, CreateApplicationRequestBody>(
    `${API_URLS.Applications}/applications`,
    data,
  );

  return result.data;
};
