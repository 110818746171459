import { AxiosResponse } from 'axios';

import { apiClient } from 'api/client/apiClient';
import { UpdateApplicationAccessesParams } from '../types';
import { API_URLS } from '../consts';

export const updateApplicationAccesses = async ({ applicationId, accesses }: UpdateApplicationAccessesParams) => {
  const result = await apiClient.put<void, AxiosResponse<void>, UpdateApplicationAccessesParams['accesses']>(
    `${API_URLS.Applications}/applications/${applicationId}/accesses`,
    accesses,
  );

  return result.data;
};
