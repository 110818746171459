import { apiClient } from 'api/client/apiClient';
import { AccessibleResourceDTO } from '../types';
import { API_URLS } from '../consts';

interface FetchAccessibleResources {
  provider?: string;
  accessToken?: string;
  refreshToken?: string;
}

export const fetchAccessibleResources = async (params: FetchAccessibleResources) => {
  const result = await apiClient.get<AccessibleResourceDTO[]>(
    `${API_URLS.ProjectManagementSystem}/accessible-resources`,
    {
      params,
    },
  );

  return result.data;
};
