import { Route, Routes, Navigate } from 'react-router-dom';

import { Integrations } from '../app/integrations/Integrations';
import { FileIntegrations } from '../app/file-integrations/FileIntegration';
import { SdiIntegrations } from '../app/sdi-integrations/Integrations';
import { Login } from '../app/login/Login';
import { PermissionController } from '../context/auth/PermissionController/PermissionController';
import { Forbidden } from '../app/forbidden/Forbidden';
import { UploadInvoices } from '../app/uploadInvoices/UploadInvoices';
import { UploadPayrolls } from '../app/payrolls/UploadPayrolls';
import { Workflows } from 'app/workflows/Workflows';
import { ApplicationsList } from 'app/applications/pages/list';
import { ApplicationDetails } from 'app/applications/pages/details';
import { RepositoriesList } from 'app/repositories/pages/list';
import { AddRepositoryIntegration } from 'app/repositories/pages/add-integration';
import { ProjectManagementSystemsList } from 'app/project-management/pages/list';
import { AddAccessibleResourceIntegration } from 'app/project-management/pages/add-integration';

import { AppRoute } from './AppRoute.enum';
import { AuthorizedRoute } from './AuthorizedRoute';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AuthorizedRoute />}>
        <Route path="/" element={<PermissionController />}>
          <Route path={AppRoute.integrations} element={<Integrations />} />
          <Route path={AppRoute.uploadInvoices} element={<UploadInvoices />} />
          <Route path={AppRoute.fileIntegrations} element={<FileIntegrations />} />
          <Route path={AppRoute.sdiIntegrations} element={<SdiIntegrations />} />
          <Route path={AppRoute.uploadPayrolls} element={<UploadPayrolls />} />
          <Route path={AppRoute.workflows} element={<Workflows />} />
          <Route path={AppRoute.applications} element={<ApplicationsList />} />
          <Route path={AppRoute.applicationDetails} element={<ApplicationDetails />} />
          <Route path={AppRoute.repositories} element={<RepositoriesList />} />
          <Route path={AppRoute.repositoriesAddIntegration} element={<AddRepositoryIntegration />} />
          <Route path={AppRoute.projectManagementSystems} element={<ProjectManagementSystemsList />} />
          <Route path={AppRoute.addAccessibleResourceIntegration} element={<AddAccessibleResourceIntegration />} />
          <Route index element={<Navigate to={AppRoute.integrations} replace />} />
        </Route>
      </Route>
      <Route path={AppRoute.login} element={<Login />} />
      <Route path={AppRoute.forbidden} element={<Forbidden />} />
      <Route path="*" element={<Navigate to={AppRoute.integrations} replace />} />
    </Routes>
  );
};
