import { AxiosResponse } from 'axios';

import { apiClient } from 'api/client/apiClient';
import { UpdateApplicationParams } from '../types';
import { API_URLS } from '../consts';

export const updateApplication = async ({ id, ...body }: UpdateApplicationParams) => {
  const result = await apiClient.patch<void, AxiosResponse<void>, Omit<UpdateApplicationParams, 'id'>>(
    `${API_URLS.Applications}/applications/${id}`,
    body,
  );

  return result.data;
};
