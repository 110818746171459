import { PageHeader, Table, Row, Col, Input } from 'antd';
import { useQuery } from 'react-query';
import { ChangeEvent, useState } from 'react';

import { apiClient } from '../../api/client/apiClient';

import { WORKFLOW_API_URL, WORKFLOW_QUERY } from './consts';
import { RunWorkflow } from './RunWorkflow';
import { Workflow } from './Workflows.types';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_: string, record: Workflow) => {
      return (
        <div className="actions">
          <RunWorkflow stateMachineArn={record.stateMachineArn} />
        </div>
      );
    },
  },
];

export const Workflows = () => {
  const query = useQuery<Workflow[]>(WORKFLOW_QUERY, async () => {
    const result = await apiClient.get(`${WORKFLOW_API_URL}/workflows`);
    return result.data;
  });

  const [filter, setFilter] = useState({
    name: '',
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const filteredData = query.data?.filter((workflow) => {
    return workflow.name.toLowerCase().includes(filter.name.toLowerCase());
  });

  return (
    <>
      <Row>
        <Col span={12}>
          <PageHeader title="Workflows list" />
        </Col>
      </Row>

      <div className="content-body">
        <Row className="filter-row" style={{ marginBottom: '1rem' }}>
          <Col span={12}>
            <Input placeholder="Filter by name" name="name" value={filter.name} onChange={handleFilterChange} />
          </Col>
        </Row>

        <Table
          loading={query.isLoading}
          dataSource={filteredData}
          columns={columns}
          rowKey="id"
          size="middle"
          pagination={{
            pageSize: 20,
          }}
        />
      </div>
    </>
  );
};
