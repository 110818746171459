import { Row, Col, PageHeader, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { AddNewRepositoryButton } from 'app/repositories/components/add-new-repository-button';

import { useRepositoriesList } from './hooks';

export const RepositoriesList = () => {
  const { dataSource, loading, columns } = useRepositoriesList();

  return (
    <Content>
      <Row>
        <Col span={12}>
          <PageHeader title="Repositories list" />
        </Col>
        <Col span={12} className="add-configuration">
          <AddNewRepositoryButton />
        </Col>
      </Row>

      <div className="content-body">
        <Table
          {...{ dataSource, loading, columns }}
          rowKey="id"
          size="middle"
          pagination={{
            pageSize: 20,
          }}
        />
      </div>
    </Content>
  );
};
