export enum SdiColumnType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Money = 'money',
}

export type SdiMapping = {
  source: {
    columnName: string; // e.g. "First name"
  };
  target: {
    type: SdiColumnType;
    dbFieldName: string;
  };
};

export interface SdiPayload {
  googleSheets: {
    id: string; // e.g. "1X2Y3Z4W5V6U7T8S9R0Q1P2O3N4M5L6K7J8I9H0G1F2E3D4C5B6A7"
    tab: string; // e.g. "People"
    range: string; // e.g. "A1:Z100"
    pageSize: number; // e.g. 100
  };
  db: {
    tableName: string; // e.g. "people"
  };
  mapping: SdiMapping[];
}

export type SdiIntegrationId = `SDI:SHEET_NAME:${string}:SPREAD_SHEET_ID:${string}:TABLE_NAME:${string}`;

export type SdiIntegration = {
  id?: SdiIntegrationId;
  scheduleExpression?: string;
  uuid?: string;
  cronTime?: string;
  cronDefinition?: string;
  cronDay?: string;
  payload: SdiPayload;
};

interface DataInterfaceMapping {
  sourceColumnName: string;
  targetFieldName: string;
  targetFieldType: SdiColumnType;
}

export interface DataInterface {
  id?: string;
  alias?: string;
  cronTime: string;
  cronDefinition: string;
  cronDay: string;
  scheduleExpression?: string;
  spreadsheetId: string;
  sheetName: string;
  range: string;
  pageSize: number;
  dbTableName: string;
  mapping: DataInterfaceMapping[];
}
