import { useEffect, useState } from 'react';
import { Button, Alert, Form, Input, Modal, Select, Spin, TimePicker, Popconfirm } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { ApiError } from '../../api/client/types';
import { apiClient } from '../../api/client/apiClient';
import { CRON_DAYS, cronDefinition, ONCE_A_WEEK, CUSTOM } from 'shared/schedule-expression-consts';
import { getCronFromScheduleExpression } from '../../shared/get-cron-from-schedule-expression.util';

import { DataSourceEnum, dataSourceTypeLabels, Integration, IntegrationType, integrationTypeLabels } from './types';
import { DATA_SOURCE_QUERY, INTEGRATIONS_API_URL, INTEGRATIONS_QUERY } from './consts';
import { parsePostData } from './AddIntegration';

interface EditIntegrationProps {
  integration: Integration;
}

export const EditIntegration = ({ integration }: EditIntegrationProps) => {
  const initialCronDefinition = getCronFromScheduleExpression(integration?.scheduleExpression);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (postData: Integration) => {
      setIsLoading(true);
      await apiClient.patch(`${INTEGRATIONS_API_URL}/integrations/${integration.id}`, parsePostData(postData));
    },
    {
      onSuccess: async () => {
        form.resetFields();
        setIsModalVisible(false);
        await queryClient.invalidateQueries(INTEGRATIONS_QUERY);
      },
      onError: (error: AxiosError<ApiError>) => {
        if ([400, 409].includes(error.response?.data.statusCode as number)) {
          setApiError(error.response?.data);
        }
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const dataSourceType: DataSourceEnum = Form.useWatch('dataSourceType', form);
  const [apiError, setApiError] = useState<ApiError>();
  const [showCronTime, setShowCronTime] = useState(false);
  const [showCronDay, setShowCronDay] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>();
  const [showCronCustom, setShowCronCustom] = useState<boolean>(false);

  useEffect(() => {
    if (isModalVisible) {
      form.resetFields();
      setShowCronTime(false);
      setShowCronDay(false);
      setShowCronCustom(initialCronDefinition?.cronDefinition === CUSTOM);
    }
  }, [isModalVisible]);

  const query = useQuery<string[]>(DATA_SOURCE_QUERY, async () => {
    const result = await apiClient.get(`${INTEGRATIONS_API_URL}/data-sources`);
    return result.data;
  });
  const showModal = () => {
    setIsModalVisible(true);
    query.refetch();
  };

  const handleFilterInput = (value: string) => {
    setSelectedValue(value);
  };

  const handleConfirm = () => {
    form.validateFields().then((data: Integration) => {
      mutation.mutate(data);
      form.resetFields();
    });
  };

  const onChangeCronDefinition = (value: string) => {
    setShowCronTime(value.startsWith('once_a'));
    setShowCronDay(value === ONCE_A_WEEK);
    setShowCronCustom(value === CUSTOM);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Button onClick={showModal} style={{ borderColor: 'blue', color: 'blue' }}>
        Edit
      </Button>
      <Modal
        title="Edit integration"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="Close" onClick={handleCancel}>
            Cancel
          </Button>,
          <Popconfirm
            key="confirm"
            title="Are you sure you want to proceed?"
            onConfirm={handleConfirm}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Edit</Button>
          </Popconfirm>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" form={form}>
            {apiError && <Alert message={apiError.description} type="error" className="form-errors" />}
            <Form.Item
              label="Data source type"
              name="dataSourceType"
              initialValue={integration.dataSourceType}
              rules={[{ required: true, message: 'Please input your type' }]}
            >
              <Select
                options={Object.values(DataSourceEnum).map((type) => ({
                  label: dataSourceTypeLabels[type],
                  value: type,
                }))}
              />
            </Form.Item>
            {dataSourceType === DataSourceEnum.QUERY ? (
              <div>
                <Form.Item
                  label="SQL Data source"
                  name="sqlDataSource"
                  rules={[
                    { required: true, message: 'Please input SQL query' },
                    {
                      min: 3,
                      message: 'Data source must be min 3 characters',
                    },
                  ]}
                  initialValue={integration.dataSource}
                >
                  <textarea placeholder="Type your SQL here!" rows={5} cols={39}></textarea>
                </Form.Item>
                <Form.Item
                  label="alias"
                  name="alias"
                  initialValue={integration.alias}
                  rules={[
                    { required: true, message: 'Please input alias!' },
                    {
                      min: 3,
                      message: 'Alias must be min 3 characters',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            ) : (
              <Form.Item
                label="Data source"
                name="dataSource"
                rules={[
                  { required: true, message: 'Please input your data source' },
                  {
                    min: 3,
                    max: 255,
                    message: 'Data source must be between 3 and 255 characters',
                  },
                ]}
                initialValue={integration.dataSource}
              >
                <Select
                  showSearch
                  options={query.data?.map((option) => ({ value: option, label: option }))}
                  onChange={handleFilterInput}
                  value={selectedValue}
                  placeholder="Select a data source"
                />
              </Form.Item>
            )}

            <Form.Item
              label="Sheet name"
              name="sheetName"
              rules={[
                { required: true, message: 'Please input your Sheet name' },
                {
                  min: 3,
                  max: 255,
                  message: 'Sheet name must be between 3 and 255 characters',
                },
              ]}
              initialValue={integration.sheetName}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Spreadsheet Id"
              name="spreadsheetId"
              rules={[
                { required: true, message: 'Please input your ID!' },
                {
                  min: 3,
                  message: 'Spreadsheet Id must be between 3 characters',
                },
              ]}
              initialValue={integration.spreadsheetId}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Target"
              name="type"
              rules={[{ required: true, message: 'Please input your type' }]}
              initialValue={integration.type}
            >
              <Select
                options={Object.values(IntegrationType).map((type) => ({
                  label: integrationTypeLabels[type],
                  value: type,
                }))}
              />
            </Form.Item>
            <Form.Item label="Schedule" name="cronDefinition" initialValue={initialCronDefinition?.cronDefinition}>
              <Select options={cronDefinition} onChange={onChangeCronDefinition} />
            </Form.Item>
            <div style={showCronTime ? { display: 'inline' } : { display: 'none' }}>
              <Form.Item
                name="cronTime"
                label="Select schedule time"
                rules={[{ required: showCronTime, message: 'Please select cron time' }]}
                initialValue={integration.cronTime}
              >
                <TimePicker format="HH:mm" />
              </Form.Item>
            </div>
            <div style={showCronDay ? { display: 'inline' } : { display: 'none' }}>
              <Form.Item
                label="Select schedule day"
                name="cronDay"
                rules={[{ required: showCronDay, message: 'Please select cron day' }]}
                initialValue={integration.cronDay}
              >
                <Select options={CRON_DAYS.map((d) => ({ label: d, value: d }))} />
              </Form.Item>
            </div>
            <div style={showCronCustom ? { display: 'inline' } : { display: 'none' }}>
              <Form.Item
                label="Custom expression"
                name="scheduleExpression"
                initialValue={integration?.scheduleExpression}
                rules={[{ required: showCronCustom, message: 'Please provide custom cron expression' }]}
              >
                <Input placeholder="e.g. cron(0 0 1 * ? *)" />
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
