import { useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { useQuery } from 'react-query';

import { apiClient } from '../../api/client/apiClient';

import { FILE_INTEGRATIONS_API_URL, FILE_INTEGRATIONS_HISTORY_QUERY } from './consts';
import { FormField } from './FileIntegration.types';
import { FileIntegrationHistoryProps, FileIntegrationUploadHistory } from './FileIntegrationHistory.types';

const columns = [
  { title: 'Name', dataIndex: 'integrationName', key: 'integrationName' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
  { title: 'Creation date', dataIndex: 'createdAt', key: 'createdAt' },
];

export const FileIntegrationHistory = ({ fileIntegration }: FileIntegrationHistoryProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const query = useQuery(
    FILE_INTEGRATIONS_HISTORY_QUERY,
    async () => {
      const result = await apiClient.get<FileIntegrationUploadHistory[]>(
        `${FILE_INTEGRATIONS_API_URL}/file-integration-history/${fileIntegration[FormField['integrationName']]}`,
      );
      return result.data;
    },
    { enabled: false },
  );
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(true);
    query.refetch();
  };

  return (
    <>
      <Button onClick={showModal}>History</Button>
      <Modal
        title="History"
        width="auto"
        visible={isModalVisible}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Table
          loading={query.isLoading}
          dataSource={query.data?.map((integrationHistory) => ({
            ...integrationHistory,
            createdAt: new Date(integrationHistory.createdAt).toISOString(),
          }))}
          columns={columns}
          rowKey="id"
          size="middle"
          pagination={false}
        />
      </Modal>
    </>
  );
};
