import React, { useContext, createContext, useMemo, useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { Spin } from 'antd';
import { useQuery } from 'react-query';

import { AppRoute } from 'routing/AppRoute.enum';
import { GIT_REPOSITORIES_QUERY_KEY, GIT_WORKSPACES_QUERY_KEY } from 'app/repositories/consts';
import { fetchGitWorkspaces } from 'app/repositories/api/fetchGitWorkspaces';
import { searchGitRepositories } from 'app/repositories/api/searchGitRepositories';
import { useDebounce } from 'hooks/useDebounce';

import { RepositoryIntegrationContextType } from './types';
import { useOAuthValidation } from './hooks';

export const RepositoryIntegrationContext = createContext<RepositoryIntegrationContextType | undefined>(undefined);

export const RepositoryIntegrationProvider = ({ children }: { children: React.ReactNode }) => {
  const { isValid, loading, oauthData } = useOAuthValidation();
  const [selectedWorkspace, setSelectedWorkspace] = useState<string>();
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data: workspaces = [], isLoading: loadingWorkspaces = false } = useQuery(
    [GIT_WORKSPACES_QUERY_KEY, oauthData?.provider],
    () =>
      fetchGitWorkspaces({
        accessToken: oauthData?.accessToken,
        provider: oauthData?.provider,
        refreshToken: oauthData?.refreshToken,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
      enabled: isValid,
      onSuccess: (data) => {
        setSelectedWorkspace(data?.[0]?.uuid || undefined);
      },
    },
  );

  const { data: repositories = [], isLoading: loadingRepositories = false } = useQuery(
    [GIT_REPOSITORIES_QUERY_KEY, oauthData?.provider, selectedWorkspace, debouncedSearchQuery],
    () =>
      searchGitRepositories({
        accessToken: oauthData?.accessToken,
        provider: oauthData?.provider,
        refreshToken: oauthData?.refreshToken,
        // Query is disabled when selectedWorkspace is not set
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        workspaceId: selectedWorkspace!,
        query: debouncedSearchQuery,
      }),
    { refetchOnWindowFocus: false, retry: false, retryOnMount: false, enabled: isValid && !!selectedWorkspace },
  );

  const onSelectWorkspace = useCallback((workspaceId: string) => {
    setSelectedWorkspace(workspaceId);
  }, []);

  const contextValue = useMemo(
    () => ({
      accessToken: oauthData?.accessToken,
      refreshToken: oauthData?.refreshToken,
      provider: oauthData?.provider,
      selectedWorkspace,
      workspaces,
      loadingWorkspaces,
      onSelectWorkspace,
      repositories,
      loadingRepositories,
      searchQuery,
      setSearchQuery,
      expiryAt: oauthData?.expiryAt,
    }),
    [
      oauthData,
      workspaces,
      loadingWorkspaces,
      selectedWorkspace,
      onSelectWorkspace,
      repositories,
      loadingRepositories,
      searchQuery,
      setSearchQuery,
    ],
  );

  if (loading) {
    return <Spin />;
  }

  if (!isValid) {
    return <Navigate to={AppRoute.repositories} />;
  }

  return (
    <RepositoryIntegrationContext.Provider value={contextValue as RepositoryIntegrationContextType}>
      {children}
    </RepositoryIntegrationContext.Provider>
  );
};

export const useRepositoryIntegrationContext = (): RepositoryIntegrationContextType => {
  const context = useContext(RepositoryIntegrationContext);

  if (context === undefined) {
    throw new Error('RepositoryIntegrationContext must be within RepositoryIntegrationProvider');
  }

  return context;
};
