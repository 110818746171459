import Typography from 'antd/lib/typography';

import { useProjectManagementContext } from 'app/project-management/context/project-management-integration';

export const AddAccessibleResourceTitle = () => {
  const { provider } = useProjectManagementContext();

  return (
    <>
      <Typography.Title style={{ marginBottom: '0.5rem' }}>Import project</Typography.Title>
      <Typography.Title style={{ marginTop: 0 }} level={4}>
        Provider: {provider}
      </Typography.Title>
    </>
  );
};
