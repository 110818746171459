import { AxiosResponse } from 'axios';

import { apiClient } from 'api/client/apiClient';
import { RepositoryDTO } from '../types';
import { API_URLS } from '../consts';

interface ImportGitRepositoryRequestBody {
  provider: string;
  repositoryId: string;
  accessToken: string;
  refreshToken: string;
  workspaceId: string;
  expiryAt: Date | null;
  projectId: string;
}

export const importGitRepository = async (data: ImportGitRepositoryRequestBody) => {
  const result = await apiClient.post<RepositoryDTO, AxiosResponse<RepositoryDTO>, ImportGitRepositoryRequestBody>(
    `${API_URLS.Repositories}/import-git-repo`,
    data,
  );

  return result.data;
};
