import { useMutation, useQuery } from 'react-query';
import notification from 'antd/lib/notification';
import { useCallback } from 'react';

import { AVAILABLE_GIT_PROVIDERS_QUERY_KEY } from 'app/repositories/consts';
import { generateAuthorizationUrl, fetchAvailableProviders, ProviderAvailability } from 'app/repositories/api';

export const useAddNewRepositoryButton = () => {
  const { data = [], isLoading } = useQuery(AVAILABLE_GIT_PROVIDERS_QUERY_KEY, fetchAvailableProviders, {
    onError: () => {
      notification.error({ message: 'Cannot fetch repositories list!' });
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { mutateAsync: callGenerateAuthorizationUrl } = useMutation(generateAuthorizationUrl, {
    retry: false,
    onSuccess: (data) => {
      window.location.replace(data.authorizationUrl);
    },
    onError: () => {
      notification.error({ message: 'Cannot update application!' });
    },
  });

  const onClick = useCallback(
    (provider: ProviderAvailability) => {
      if (!provider.isAvailable) {
        return;
      }

      callGenerateAuthorizationUrl({ provider: provider.provider });
    },
    [callGenerateAuthorizationUrl],
  );

  return { providers: data, isLoading, onClick };
};
