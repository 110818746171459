import { PageHeader, Table, Row, Col, Input } from 'antd';
import { useQuery } from 'react-query';
import { ChangeEvent, useState } from 'react';

import { apiClient } from '../../api/client/apiClient';

import { FILE_INTEGRATIONS_API_URL, FILE_INTEGRATIONS_QUERY } from './consts';
import { AddFileIntegration } from './AddFileIntegration';
import { DeleteFileIntegration } from './DeleteFileIntegration';
import { FileIntegration } from './FileIntegration.types';
import { FileIntegrationUpload } from './UploadFile';
import { FileIntegrationHistory } from './FileIntegrationHistory';

const columns = [
  {
    title: 'Integration name',
    dataIndex: 'integrationName',
    key: 'integrationName',
  },
  { title: 'Sheet name', dataIndex: 'sheetName', key: 'sheetName' },
  { title: 'First row index', dataIndex: 'firstRowIndex', key: 'firstRowIndex' },

  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_: string, record: FileIntegration) => (
      <div className="actions">
        <DeleteFileIntegration fileIntegration={record} />
        <FileIntegrationUpload fileIntegration={record} />
        <FileIntegrationHistory fileIntegration={record} />
      </div>
    ),
  },
];
export const FileIntegrations = () => {
  const query = useQuery<FileIntegration[]>(FILE_INTEGRATIONS_QUERY, async () => {
    const result = await apiClient.get(`${FILE_INTEGRATIONS_API_URL}/file-integrations`);
    return result.data;
  });

  const [filter, setFilter] = useState({
    integrationName: '',
    sheetName: '',
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const filteredData = query.data?.filter((integration) => {
    return (
      integration.integrationName.toLowerCase().includes(filter.integrationName.toLowerCase()) &&
      integration.sheetName.toLowerCase().includes(filter.sheetName.toLowerCase())
    );
  });

  const expandedRowRender = (record: FileIntegration) => {
    const columns = [
      {
        title: 'Column name',
        dataIndex: 'columnName',
        key: 'columnName',
      },
      {
        title: 'Column type',
        dataIndex: 'columnType',
        key: 'columnType',
      },
      {
        title: 'Spreadsheet column',
        dataIndex: 'columnSpreadsheet',
        key: 'columnSpreadsheet',
      },
    ];
    return <Table columns={columns} dataSource={record.columns} pagination={false} rowKey="columnName" size="small" />;
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <PageHeader title="File integration configuration" />
        </Col>
        <Col span={12} className="add-configuration">
          <AddFileIntegration />
        </Col>
      </Row>

      <div className="content-body">
        <Row className="filter-row" style={{ marginBottom: '1rem' }}>
          <Col span={12}>
            <Input
              placeholder="Filter by integration name"
              name="integrationName"
              value={filter.integrationName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col span={12}>
            <Input
              placeholder="Filter by sheet name"
              name="sheetName"
              value={filter.sheetName}
              onChange={handleFilterChange}
            />
          </Col>
        </Row>

        <Table
          loading={query.isLoading}
          dataSource={filteredData}
          columns={columns}
          rowKey="id"
          size="middle"
          pagination={false}
          expandable={{
            expandedRowRender,
          }}
        />
      </div>
    </>
  );
};
