import { useMutation } from 'react-query';
import { useCallback, useState } from 'react';
import Axios from 'axios';

import { useProjectManagementContext } from 'app/project-management/context/project-management-integration';
import { importProject } from 'app/project-management/api/importProject';

export const useImportProject = (projectId: string) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const { provider, accessToken, refreshToken, selectedResource, expiryAt } = useProjectManagementContext();
  const { mutate, isLoading, isSuccess, isError } = useMutation(importProject, {
    onError: (error) => {
      if (Axios.isAxiosError(error) && error.response?.status === 409) {
        setErrorMessage('Project is already imported');
      } else {
        setErrorMessage('Cannot import project');
      }
    },
  });

  const onClick = useCallback(() => {
    if (isSuccess || isError) return;

    mutate({
      accessToken,
      provider,
      refreshToken,
      projectId,
      resourceId: selectedResource,
      expiryAt,
    });
  }, [accessToken, refreshToken, provider, projectId, selectedResource, isSuccess, isError, expiryAt]);

  return { isLoading, isSuccess, isError, onClick, errorMessage };
};
