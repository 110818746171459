import { useCallback, useEffect, useMemo, useReducer } from 'react';

import { AuthContext } from '../authContext/AuthContext';
import { authStorage } from '../authStorage/AuthStorage';
import { authReducer } from '../authReducer/authReducer';
import { resetTokens, setTokens } from '../authActionCreators/authActionCreators';
import { AuthContextValue } from '../authContext/AuthContext.types';
import { useUser } from 'hooks/useUser/useUser';

import { AuthContextControllerProps } from './AuthContextController.types';

export const AuthContextController = ({ children }: AuthContextControllerProps) => {
  const [state, dispatch] = useReducer(authReducer, {
    accessToken: authStorage.accessToken,
  });
  const { user, isAuthenticated } = useUser();

  const logout = useCallback(() => {
    dispatch(resetTokens());
  }, []);

  const login = useCallback(
    (accessToken: string) => {
      dispatch(
        setTokens({
          accessToken,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    authStorage.accessToken = state.accessToken;
  }, [state]);

  const value: AuthContextValue = useMemo(
    () => ({
      ...state,
      isAuthenticated,
      login,
      logout,
      user,
    }),
    [state, login, logout, user, isAuthenticated],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
