import { Popconfirm, Button } from 'antd';
import { useQueryClient } from 'react-query';

import { apiClient } from '../../api/client/apiClient';

import { Integration } from './types';
import { INTEGRATIONS_API_URL, INTEGRATIONS_QUERY } from './consts';

interface DeleteIntegrationProps {
  integration: Integration;
}

export const DeleteIntegration = ({ integration }: DeleteIntegrationProps) => {
  const queryClient = useQueryClient();

  const onConfirm = async () => {
    await apiClient.delete(`${INTEGRATIONS_API_URL}/integrations/${integration.id}`);
    await queryClient.invalidateQueries(INTEGRATIONS_QUERY);
  };

  return (
    <Popconfirm title="Are you sure to delete this integration?" onConfirm={onConfirm} okText="Yes" cancelText="No">
      <Button danger>Delete</Button>
    </Popconfirm>
  );
};
