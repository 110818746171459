import { useMutation, useQuery } from 'react-query';
import notification from 'antd/lib/notification';
import { useCallback } from 'react';

import { AVAILABLE_PROJECT_PROVIDERS_QUERY } from 'app/project-management/consts';
import { generateAuthorizationUrl, fetchAvailableProviders } from 'app/project-management/api';

export const useAddNewProjectButton = () => {
  const { data = [], isLoading } = useQuery(AVAILABLE_PROJECT_PROVIDERS_QUERY, fetchAvailableProviders, {
    onError: () => {
      notification.error({ message: 'Cannot fetch project providers list!' });
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { mutateAsync: callGenerateAuthorizationUrl } = useMutation(generateAuthorizationUrl, {
    retry: false,
    onSuccess: (data) => {
      window.location.replace(data.authorizationUrl);
    },
    onError: () => {
      notification.error({ message: 'Cannot update application!' });
    },
  });

  const onClick = useCallback(
    (provider: string) => {
      callGenerateAuthorizationUrl({ provider });
    },
    [callGenerateAuthorizationUrl],
  );

  return { providers: data, isLoading, onClick };
};
