import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback, useMemo, useState } from 'react';
import { TableColumnProps, notification } from 'antd';
import { Link, generatePath } from 'react-router-dom';

import { ApplicationDTO, CreateApplicationRequestBody } from '../../types';
import { APPLICATIONS_QUERY_KEY } from '../../consts';
import { fetchApplications } from '../../api/fetchApplications';
import { AppRoute } from 'routing/AppRoute.enum';
import { createApplication } from 'app/applications/api/createApplication';

const useApplicationsListColumns = (): TableColumnProps<ApplicationDTO>[] =>
  useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => <Link to={generatePath(AppRoute.applicationDetails, { id: record.id })}>{name}</Link>,
      },
      {
        title: 'Number of API keys',
        dataIndex: 'apiKeys',
        key: 'apiKeys',
        render: (apiKeys) => apiKeys?.length ?? 0,
      },
    ],
    [],
  );

export const useApplicationsList = () => {
  const queryClient = useQueryClient();
  const columns = useApplicationsListColumns();
  const [isCreateApplicationModalVisible, setCreateApplicationModalVisible] = useState(false);
  const { data, isLoading } = useQuery(APPLICATIONS_QUERY_KEY, fetchApplications, {
    onError: () => {
      notification.error({ message: 'Cannot fetch applications list!' });
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
  const { mutateAsync: callCreateApplication } = useMutation(createApplication, {
    onError: (error: string) => {
      notification.error({ message: error });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(APPLICATIONS_QUERY_KEY);
    },
    retry: false,
  });

  const openCreateApplicationModal = useCallback(() => setCreateApplicationModalVisible(true), []);
  const closeCreateApplicationModal = useCallback(() => setCreateApplicationModalVisible(false), []);

  const onSubmitCreateApplicationForm = useCallback(
    async (data: CreateApplicationRequestBody) => {
      await callCreateApplication(data);
      closeCreateApplicationModal();
    },
    [callCreateApplication, closeCreateApplicationModal],
  );

  return {
    dataSource: data,
    loading: isLoading,
    columns,
    openCreateApplicationModal,
    closeCreateApplicationModal,
    onSubmitCreateApplicationForm,
    isCreateApplicationModalVisible,
  };
};
