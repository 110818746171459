import { Button } from 'antd';
import { ProjectDTO } from 'app/project-management/types';

import { useRemoveProjectIntegration } from './hooks';

interface RemoveProjectIntegrationProps {
  project: ProjectDTO;
}

export const RemoveProjectIntegration = ({ project }: RemoveProjectIntegrationProps) => {
  const { onClick, isLoading } = useRemoveProjectIntegration(project);

  return (
    <Button danger onClick={onClick} loading={isLoading}>
      Delete project
    </Button>
  );
};
