import { Content } from 'antd/lib/layout/layout';
import { Col, Row, Space } from 'antd';

import { ProjectManagementIntegrationProvider } from 'app/project-management/context/project-management-integration';

import { ResourceSelector } from './components/resource-selector';
import { Projects } from './components/projects';
import { AddAccessibleResourceTitle } from './components/add-accessible-resource-title';
import { ProjectsSearch } from './components/projects-search';

export const AddAccessibleResourceIntegration = () => {
  return (
    <ProjectManagementIntegrationProvider>
      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          padding: 48,
        }}
      >
        <Space direction="vertical" size="large">
          <AddAccessibleResourceTitle />
          <Row>
            <Col style={{ gap: 16, display: 'flex' }} md={12}>
              <ResourceSelector />
              <ProjectsSearch />
            </Col>
          </Row>
          <Projects />
        </Space>
      </Content>
    </ProjectManagementIntegrationProvider>
  );
};
