import { useState } from 'react';
import { Button, Modal, Form, Input, Select, Alert, Spin, TimePicker } from 'antd';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { ApiError } from '../../api/client/types';
import { apiClient } from '../../api/client/apiClient';
import { getScheduleExpression } from '../../shared/get-schedule-expression.util';
import { cronDefinition, CRON_DAYS, ONCE_A_WEEK, CUSTOM } from 'shared/schedule-expression-consts';

import { DataSourceEnum, dataSourceTypeLabels, Integration, IntegrationType, integrationTypeLabels } from './types';
import { DATA_SOURCE_QUERY, INTEGRATIONS_API_URL, INTEGRATIONS_QUERY } from './consts';

interface DataInterface {
  dataSourceType: DataSourceEnum;
  sqlDataSource: string;
  dataSource: string;
  alias?: string;
  type: IntegrationType;
  sheetName: string;
  spreadsheetId: string;
  cronTime: string;
  cronDefinition: string;
  cronDay: string;
  scheduleExpression?: string;
}

export const parsePostData = (data: DataInterface): Integration => {
  const result = {
    dataSource: data.dataSourceType === DataSourceEnum.QUERY ? data.sqlDataSource : data.dataSource,
    type: data.type as IntegrationType,
    sheetName: data.sheetName,
    spreadsheetId: data.spreadsheetId,
    dataSourceType: data.dataSourceType,
    alias: data.dataSourceType === DataSourceEnum.QUERY ? data.alias : undefined,
  } as Integration;

  const scheduleExpression = getScheduleExpression(data);

  if (scheduleExpression) {
    result.scheduleExpression = scheduleExpression;
  }

  return result;
};

export const AddIntegration = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const dataSourceType: DataSourceEnum = Form.useWatch('dataSourceType', form);
  const [apiError, setApiError] = useState<ApiError>();
  const [showCronTime, setShowCronTime] = useState(false);
  const [showCronDay, setShowCronDay] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>();
  const [showCronCustom, setShowCronCustom] = useState<boolean>(false);

  const query = useQuery<string[]>(DATA_SOURCE_QUERY, async () => {
    const result = await apiClient.get(`${INTEGRATIONS_API_URL}/data-sources`);
    return result.data;
  });

  const handleFilterInput = (value: string) => {
    setSelectedValue(value);
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (postData: Integration) => {
      setIsLoading(true);
      await apiClient.post(`${INTEGRATIONS_API_URL}/integrations`, parsePostData(postData));
    },
    {
      onSuccess: async () => {
        form.resetFields();
        setIsModalVisible(false);
        await queryClient.invalidateQueries(INTEGRATIONS_QUERY);
      },
      onError: (error: AxiosError<ApiError>) => {
        if ([400, 409].includes(error.response?.data.statusCode as number)) {
          setApiError(error.response?.data);
        }
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );

  const showModal = () => {
    setApiError(undefined);
    form.setFieldsValue({ type: IntegrationType.GOOGLE_SPREADSHEET, dataSourceType: DataSourceEnum.TABLE });
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields().then((data: Integration) => {
      mutation.mutate(data);
      form.resetFields();
    });
  };

  const onChangeCronDefinition = (value: string) => {
    setShowCronTime(value.startsWith('once_a'));
    setShowCronDay(value === ONCE_A_WEEK);
    setShowCronCustom(value === CUSTOM);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add integration
      </Button>
      <Modal title="Add integration" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okText={'Add'}>
        <Spin spinning={isLoading}>
          <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off" form={form}>
            {apiError && <Alert message={apiError.description} type="error" className="form-errors" />}
            <Form.Item
              label="Data source type"
              name="dataSourceType"
              rules={[{ required: true, message: 'Please input your type' }]}
            >
              <Select
                options={Object.values(DataSourceEnum).map((type) => ({
                  label: dataSourceTypeLabels[type],
                  value: type,
                }))}
              />
            </Form.Item>
            {dataSourceType === DataSourceEnum.QUERY ? (
              <div>
                <Form.Item
                  label="SQL Data source"
                  name="sqlDataSource"
                  rules={[
                    { required: true, message: 'Please input SQL query' },
                    {
                      min: 3,
                      message: 'Data source must be min 3 characters',
                    },
                  ]}
                >
                  <textarea placeholder="Type your SQL here!" rows={5} cols={39}></textarea>
                </Form.Item>
                <Form.Item
                  label="alias"
                  name="alias"
                  rules={[
                    { required: true, message: 'Please input alias!' },
                    {
                      min: 3,
                      message: 'Alias must be min 3 characters',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            ) : (
              <Form.Item
                label="Data source"
                name="dataSource"
                rules={[
                  { required: true, message: 'Please input your data source' },
                  {
                    min: 3,
                    max: 255,
                    message: 'Data source must be between 3 and 255 characters',
                  },
                ]}
              >
                <Select
                  showSearch
                  options={query.data?.map((option) => ({ value: option, label: option }))}
                  onChange={handleFilterInput}
                  value={selectedValue}
                  placeholder="Select a data source"
                />
              </Form.Item>
            )}

            <Form.Item
              label="Sheet name"
              name="sheetName"
              rules={[
                { required: true, message: 'Please input your Sheet name' },
                {
                  min: 3,
                  max: 255,
                  message: 'Sheet name must be between 3 and 255 characters',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Alert
              message="In order to add push data to many spreadsheets at once use ',' as separator. For example: <sheet-id-1>,<sheet-id-2>"
              type="info"
              className="form-errors"
            />
            <Form.Item
              label="Spreadsheet Id"
              name="spreadsheetId"
              rules={[
                { required: true, message: 'Please input your ID!' },
                {
                  min: 3,
                  message: 'Spreadsheet Id must be minimum 3 characters',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Target" name="type" rules={[{ required: true, message: 'Please input your type' }]}>
              <Select
                options={Object.values(IntegrationType).map((type) => ({
                  label: integrationTypeLabels[type],
                  value: type,
                }))}
              />
            </Form.Item>
            <Form.Item label="Schedule" name="cronDefinition">
              <Select options={cronDefinition} onChange={onChangeCronDefinition} />
            </Form.Item>
            <div style={showCronTime ? { display: 'inline' } : { display: 'none' }}>
              <Form.Item
                name="cronTime"
                label="Select schedule time"
                rules={[{ required: showCronTime, message: 'Please select cron time' }]}
              >
                <TimePicker format="HH:mm" />
              </Form.Item>
            </div>
            <div style={showCronDay ? { display: 'inline' } : { display: 'none' }}>
              <Form.Item
                label="Select schedule day"
                name="cronDay"
                rules={[{ required: showCronDay, message: 'Please select cron day' }]}
              >
                <Select options={CRON_DAYS.map((d) => ({ label: d, value: d }))} />
              </Form.Item>
            </div>
            <div style={showCronCustom ? { display: 'inline' } : { display: 'none' }}>
              <Form.Item
                label="Custom expression"
                name="scheduleExpression"
                rules={[{ required: showCronCustom, message: 'Please provide custom cron expression' }]}
              >
                <Input placeholder="e.g. cron(0 0 1 * ? *)" />
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
