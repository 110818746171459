import { Button, Col, Form, Row, Typography } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import { AccessPicker } from 'app/applications/components/access-picker';

import { useApplicationAccesses } from './hooks';

export const ApplicationDetailsAccess = () => {
  const { form, hasChanged, initialValues, onUpdate, onCancel, isLoading } = useApplicationAccesses();

  return (
    <Form {...{ form, initialValues }} onFinish={onUpdate}>
      <Row>
        <Col span={12}>
          <Typography.Title level={3}>Application access</Typography.Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {hasChanged && (
            <>
              <Button loading={isLoading} type="primary" htmlType="submit">
                Update accesses
              </Button>
              <Button disabled={isLoading} onClick={onCancel}>
                Cancel
              </Button>
            </>
          )}
        </Col>
      </Row>
      <FormItem name="accesses">
        <AccessPicker />
      </FormItem>
    </Form>
  );
};
