import { apiClient } from 'api/client/apiClient';
import { GitRepositoryDTO } from '../types';
import { API_URLS } from '../consts';

interface SearchGitRepositoriesParams {
  provider?: string;
  accessToken?: string;
  refreshToken?: string;
  workspaceId: string;
  query?: string;
}

export const searchGitRepositories = async (params: SearchGitRepositoriesParams) => {
  const result = await apiClient.get<GitRepositoryDTO[]>(`${API_URLS.Repositories}/search-git-repo`, {
    params,
  });

  return result.data;
};
