import { Table } from 'antd';
import './access-picker.css';

import { useAccessPickerData } from './hooks';
import { AccessPickerProps } from './types';

const columns = [
  {
    title: 'Table name',
    dataIndex: 'name',
    key: 'name',
  },
];

export const AccessPicker = ({ onChange, value = [], title }: AccessPickerProps = {}) => {
  const { data, isLoading, onSelect } = useAccessPickerData({ onChange });

  return (
    <Table
      title={title}
      rowKey="key"
      className="access-picker-table"
      loading={isLoading}
      rowSelection={{
        checkStrictly: false,
        onSelect: onSelect,
        hideSelectAll: true,
        selectedRowKeys: isLoading ? [] : value,
      }}
      columns={columns}
      dataSource={data}
    />
  );
};
