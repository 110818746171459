import { Button, Tooltip } from 'antd';
import { RepositoryDTO } from 'app/repositories/types';

import { useRemoveRepositoryIntegration } from './hooks';

interface RemoveRepositoryIntegrationProps {
  repository: RepositoryDTO;
}

export const RemoveRepositoryIntegration = ({ repository }: RemoveRepositoryIntegrationProps) => {
  const { available, onClick, isLoading } = useRemoveRepositoryIntegration(repository);

  return (
    <Tooltip title={available ? undefined : 'Repository was added manually, cannot remove it via panel.'}>
      <Button disabled={!available} danger onClick={onClick} loading={isLoading}>
        Delete repository
      </Button>
    </Tooltip>
  );
};
