import { Button } from 'antd';
import { ProjectDTO } from 'app/project-management/types';

import { useRecreateProjectData } from './hooks';

interface RecreateGitRepoDataProps {
  project: ProjectDTO;
}

export const RecreateProjectData = ({ project }: RecreateGitRepoDataProps) => {
  const { onClick, isLoading } = useRecreateProjectData(project);

  return (
    <Button type="primary" onClick={onClick} loading={isLoading}>
      Recreate project data
    </Button>
  );
};
