import { RepositoryDTO } from 'app/repositories/types';
import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import { REPOSITORIES_QUERY_KEY } from 'app/repositories/consts';
import { recreateGitRepositoryData } from 'app/repositories/api/recreateGitRepositoryData';

export const useRecreateGitRepoData = (repository: RepositoryDTO) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: recreateGitRepo, isLoading } = useMutation(recreateGitRepositoryData, {
    onSuccess: () => {
      queryClient.invalidateQueries(REPOSITORIES_QUERY_KEY);
      notification.success({
        message: `The workflow to recreate repository data has been started. It could take a few minutes to complete that.`,
      });
      setModalVisible(false);
    },
    onError: (error: AxiosError) => {
      notification.error({ message: error.message || 'Cannot trigger workflow to recreate repository data' });
    },
  });

  const onConfirm = useCallback(
    ({ fromYear }: { fromYear: number }) => {
      recreateGitRepo({ repositoryId: repository.id, fromYear });
    },
    [repository, recreateGitRepo],
  );

  const onClick = useCallback(() => {
    setModalVisible(true);
  }, []);

  const onCancel = useCallback(() => {
    setModalVisible(false);
  }, []);

  return { onClick, onConfirm, onCancel, isLoading, isModalVisible };
};
