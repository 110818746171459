import React, { useContext, createContext, useMemo, useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { Spin } from 'antd';
import { useQuery } from 'react-query';

import { AppRoute } from 'routing/AppRoute.enum';
import { ACCESSIBLE_RESOURCES_QUERY_KEY, SEARCH_PROJECTS_QUERY_KEY } from 'app/project-management/consts';
import { useDebounce } from 'hooks/useDebounce';
import { searchProjects } from 'app/project-management/api/searchAccessibleResources';
import { fetchAccessibleResources } from 'app/project-management/api/fetchAccessibleResources';

import { useOAuthValidation } from './hooks';
import { ProjectManagementIntegrationContextType } from './types';

export const ProjectManagementIntegrationContext = createContext<ProjectManagementIntegrationContextType | undefined>(
  undefined,
);

export const ProjectManagementIntegrationProvider = ({ children }: { children: React.ReactNode }) => {
  const { isValid, loading, oauthData } = useOAuthValidation();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedResource, setSelectedResource] = useState<string>();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data: accessibleResources = [], isLoading: loadingAccessibleResources = false } = useQuery(
    [ACCESSIBLE_RESOURCES_QUERY_KEY, oauthData?.provider],
    () =>
      fetchAccessibleResources({
        accessToken: oauthData?.accessToken,
        provider: oauthData?.provider,
        refreshToken: oauthData?.refreshToken,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
      enabled: isValid,
      onSuccess: (data) => {
        setSelectedResource(data?.[0]?.id || undefined);
      },
    },
  );

  const { data: projects = [], isLoading: loadingProjects = false } = useQuery(
    [SEARCH_PROJECTS_QUERY_KEY, oauthData?.provider, selectedResource, debouncedSearchQuery],
    () =>
      searchProjects({
        accessToken: oauthData?.accessToken,
        provider: oauthData?.provider,
        refreshToken: oauthData?.refreshToken,
        // Query is disabled when selectedResource is not set
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        resourceId: selectedResource!,
        query: debouncedSearchQuery,
      }),
    { refetchOnWindowFocus: false, retry: false, retryOnMount: false, enabled: isValid && !!selectedResource },
  );

  const onSelectResource = useCallback((resourceId: string) => {
    setSelectedResource(resourceId);
  }, []);

  const contextValue = useMemo(
    () => ({
      accessToken: oauthData?.accessToken,
      refreshToken: oauthData?.refreshToken,
      provider: oauthData?.provider,
      accessibleResources,
      loadingAccessibleResources,
      searchQuery,
      setSearchQuery,
      selectedResource,
      setSelectedResource,
      onSelectResource,
      projects,
      loadingProjects,
      expiryAt: oauthData?.expiryAt,
    }),
    [
      oauthData,
      accessibleResources,
      loadingAccessibleResources,
      searchQuery,
      setSearchQuery,
      selectedResource,
      setSelectedResource,
      onSelectResource,
      projects,
      loadingProjects,
    ],
  );

  if (loading) {
    return <Spin />;
  }

  if (!isValid) {
    return <Navigate to={AppRoute.projectManagementSystems} />;
  }

  return (
    <ProjectManagementIntegrationContext.Provider value={contextValue as ProjectManagementIntegrationContextType}>
      {children}
    </ProjectManagementIntegrationContext.Provider>
  );
};

export const useProjectManagementContext = (): ProjectManagementIntegrationContextType => {
  const context = useContext(ProjectManagementIntegrationContext);

  if (context === undefined) {
    throw new Error('ProjectManagementIntegrationContext must be within ProjectManagementIntegrationContext');
  }

  return context;
};
