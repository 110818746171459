import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import { useRepositoryIntegrationContext } from 'app/repositories/context/repository-integration';

export const GitRepositorySearch = () => {
  const { setSearchQuery } = useRepositoryIntegrationContext();

  return (
    <Input
      placeholder="Search..."
      prefix={<SearchOutlined />}
      allowClear
      style={{ maxWidth: '300px' }}
      onChange={(event) => setSearchQuery(event.target.value)}
    />
  );
};
