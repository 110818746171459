import { Row, Col, PageHeader, Table, Button } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import { CreateApplicationModal } from '../../components/create-application-modal';

import { useApplicationsList } from './hooks';

export const ApplicationsList = () => {
  const {
    dataSource,
    loading,
    columns,
    closeCreateApplicationModal,
    onSubmitCreateApplicationForm,
    isCreateApplicationModalVisible,
    openCreateApplicationModal,
  } = useApplicationsList();

  return (
    <Content>
      <Row>
        <Col span={12}>
          <PageHeader title="Applications list" />
        </Col>
        <Col span={12} className="add-configuration">
          <Button type="primary" onClick={openCreateApplicationModal}>
            Create an application
          </Button>
        </Col>
      </Row>

      <div className="content-body">
        <Table
          {...{ dataSource, loading, columns }}
          rowKey="id"
          size="middle"
          pagination={{
            pageSize: 20,
          }}
        />
      </div>
      <CreateApplicationModal
        open={isCreateApplicationModalVisible}
        onSubmit={onSubmitCreateApplicationForm}
        onCancel={closeCreateApplicationModal}
      />
    </Content>
  );
};
