import { apiClient } from 'api/client/apiClient';
import { API_URLS } from '../consts';

interface GenerateAuthorizationUrlBody {
  provider: string;
}

interface GenerateAuthorizationUrlResponse {
  authorizationUrl: string;
  provider: string;
}

export const generateAuthorizationUrl = async ({ provider }: GenerateAuthorizationUrlBody) => {
  const result = await apiClient.post<GenerateAuthorizationUrlResponse>(
    `${API_URLS.ProjectManagementSystem}/generate-authorization-url`,
    {
      provider,
    },
  );

  return result.data;
};
