import { apiClient } from 'api/client/apiClient';
import { API_URLS } from '../consts';

interface GenerateAccessTokenBody {
  provider: string;
  code: string;
}

interface GenerateAccessTokenResponse {
  provider: string;
  accessToken: string;
  refreshToken: string;
  expiryAt: Date | null;
}

export const generateAccessToken = async ({ provider, code }: GenerateAccessTokenBody) => {
  const result = await apiClient.post<GenerateAccessTokenResponse>(
    `${API_URLS.ProjectManagementSystem}/generate-access-token`,
    {
      provider,
      code,
    },
  );

  return result.data;
};
