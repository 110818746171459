export const CUSTOM = 'custom';
export const ONCE_A_DAY = 'once_a_day';
export const ONCE_A_WEEK = 'once_a_week';
export const CRON_DAYS = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const cronDefinition = [
  { label: '', value: '' },
  { label: 'every 5 minutes', value: 'rate(5 minutes)' },
  { label: 'every 30 minutes', value: 'rate(30 minutes)' },
  { label: 'every 1 hour', value: 'rate(1 hour)' },
  { label: 'every 6 hours', value: 'rate(6 hours)' },
  { label: 'once a day at XX:XX', value: ONCE_A_DAY },
  { label: 'once a week at XX:XX', value: ONCE_A_WEEK },
  { label: 'custom', value: CUSTOM },
];
