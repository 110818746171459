import { Avatar } from 'antd';
import List from 'antd/lib/list';
import { JiraProjectDTO } from 'app/project-management/types';

import { ImportProject } from '../import-project';

export const ProjectsItem = (item: JiraProjectDTO) => (
  <List.Item actions={[<ImportProject projectId={item.id} key={`${item.id}-import-btn`} />]}>
    <List.Item.Meta
      style={{ alignItems: 'center' }}
      avatar={
        item.avatarUrls ? <Avatar style={{ backgroundColor: 'gray' }} src={item.avatarUrls['48x48']} /> : undefined
      }
      title={
        <a target="_blank" rel="noreferrer" href={item.self}>
          {item.name}
        </a>
      }
      description={item.name || ''}
    />
  </List.Item>
);
