import { apiClient } from 'api/client/apiClient';
import { JiraProjectDTO } from '../types';
import { API_URLS } from '../consts';

interface SearchProjectsParams {
  provider?: string;
  accessToken?: string;
  refreshToken?: string;
  resourceId: string;
  query?: string;
}

export const searchProjects = async (params: SearchProjectsParams) => {
  const result = await apiClient.get<JiraProjectDTO[]>(`${API_URLS.ProjectManagementSystem}/search-projects`, {
    params,
  });

  return result.data;
};
