import { Popconfirm, Button } from 'antd';

import { DeleteApplicationApiKeyConfirmButtonProps } from './types';
import { useApplicationApiKeyDeletion } from './hooks';

export const DeleteApplicationApiKeyConfirmButton = ({ apiKey }: DeleteApplicationApiKeyConfirmButtonProps) => {
  const [deleteApiKey, { isLoading }] = useApplicationApiKeyDeletion(apiKey);

  return (
    <Popconfirm title="Are you sure to delete this api key?" onConfirm={deleteApiKey} okText="Yes" cancelText="No">
      <Button loading={isLoading} danger>
        Delete
      </Button>
    </Popconfirm>
  );
};
