import axios, { AxiosError } from 'axios';
import { Button, Col, Form, InputNumber, notification, PageHeader, Row, Select, Spin, Upload, UploadProps } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';

import { ApiError } from '../../api/client/types';
import { apiClient } from '../../api/client/apiClient';
import { PresignedPost } from '../uploadInvoices/types';

import { PayrollType, UploadData } from './types';

export const UploadPayrolls = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();
  const today = new Date();

  const props: UploadProps = {
    onRemove: () => setFileList([]),
    beforeUpload: async (file) => {
      setFileList([file]);
      return false;
    },
    accept: '.pli',
    maxCount: 1,
    fileList,
  };

  const onFinish = () => {
    form.validateFields().then(async (data: UploadData) => {
      setIsLoading(true);
      try {
        const presignedData = await getPresignedData(data);
        const uploadResponse = await uploadFile(data, presignedData);
        if (uploadResponse.status === 204) {
          notification.open({
            message: 'Upload',
            description: 'File has been successfully uploaded',
          });
        }
      } catch (error) {
        let description = (error as Error).message;
        if (axios.isAxiosError(error)) {
          description = (error as AxiosError<ApiError>).response?.data.description ?? error.toString();
        }
        notification.error({
          message: 'Upload',
          description,
        });
      } finally {
        setIsLoading(false);
      }
    });
  };

  const uploadFile = async (data: UploadData, presignedPost: PresignedPost) => {
    const { file } = data.file;
    const formData = new FormData();
    Object.entries(presignedPost.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('key', `payrolls-raw/${data.year}/${data.month}/${data.type}/snapshot.pli`);
    formData.append('file', file);

    return await axios.post(presignedPost.url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  const getPresignedData = async ({ year, month, type }: UploadData): Promise<PresignedPost> => {
    const response = await apiClient.get(`${process.env.REACT_APP_FINANCE_SERVICE_URL}/payroll/create-post-url`, {
      params: { year, month, type },
    });
    return response.data;
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <PageHeader title="Upload payrolls PLI file" />
        </Col>
      </Row>
      <div className="content-body">
        <Row>
          <Col span={12}>
            <Spin spinning={isLoading}>
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
                initialValues={{ year: today.getFullYear(), month: today.getMonth() + 1, type: PayrollType.UOP }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item label="Year" name="year" rules={[{ required: true, message: 'Please type in year' }]}>
                  <InputNumber
                    min={'1970'}
                    step={1}
                    formatter={(val?: string) => (val ? parseInt(val, 10).toFixed(0).toString() : '')}
                  />
                </Form.Item>
                <Form.Item label="Month" name="month" rules={[{ required: true, message: 'Please type in month' }]}>
                  <InputNumber
                    min={'1'}
                    max={'12'}
                    step={1}
                    formatter={(val?: string) => (val ? parseInt(val, 10).toFixed(0).toString() : '')}
                  />
                </Form.Item>
                <Form.Item label="Type" name="type" rules={[{ required: true, message: 'Please input your type' }]}>
                  <Select
                    options={Object.values(PayrollType).map((type) => ({
                      label: type,
                      value: type,
                    }))}
                  />
                </Form.Item>
                <Form.Item label="File" name="file" rules={[{ required: true, message: 'Please upload file' }]}>
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Select File</Button>
                  </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Upload
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </Col>
        </Row>
      </div>
    </>
  );
};
