import { apiClient } from 'api/client/apiClient';
import { GitWorkspaceDTO } from '../types';
import { API_URLS } from '../consts';

interface FetchGitWorkspacesParams {
  provider?: string;
  accessToken?: string;
  refreshToken?: string;
}

export const fetchGitWorkspaces = async (params: FetchGitWorkspacesParams) => {
  const result = await apiClient.get<GitWorkspaceDTO[]>(`${API_URLS.Repositories}/git-workspaces`, {
    params,
  });

  return result.data;
};
