import { PageHeader, Table, Tag, Row, Col, Input } from 'antd';
import { useQuery } from 'react-query';
import { ChangeEvent, useState } from 'react';

import { apiClient } from '../../api/client/apiClient';

import { DataSourceEnum, Integration, IntegrationType, integrationTypeLabels } from './types';
import { AddIntegration } from './AddIntegration';
import { INTEGRATIONS_API_URL, INTEGRATIONS_QUERY } from './consts';
import { DeleteIntegration } from './DeleteIntegration';
import { RunIntegration } from './RunIntegration';
import { RunHistory } from './RunHistory';
import { EditIntegration } from './EditIntegration';
import './integration.css';

const columns = [
  {
    title: 'Data source',
    dataIndex: 'dataSource',
    key: 'dataSource',
    render: (_: string, record: Integration) => {
      const result = record.dataSourceType === DataSourceEnum.QUERY ? record.alias : record.dataSource;
      return (
        <div className="dataSource">
          <div className="alias">{result}</div>
          <div className="query">{<p>{record.dataSource}</p>}</div>
        </div>
      );
    },
  },

  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (type: IntegrationType) => {
      return <Tag>{integrationTypeLabels[type]}</Tag>;
    },
  },
  { title: 'Sheet name', dataIndex: 'sheetName', key: 'sheetName' },
  {
    title: 'Sheet ID',
    dataIndex: 'spreadsheetId',
    key: 'spreadsheetId',
    render: (id: string) => {
      let ids = [id];
      if (id.indexOf(',') > -1) {
        ids = id.split(',');
      }
      return ids.map((newId) => (
        <>
          <a
            key={newId}
            href={`https://docs.google.com/spreadsheets/d/${newId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {newId}
          </a>
          <br />
        </>
      ));
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_: string, record: Integration) => {
      return (
        <div className="actions">
          <RunIntegration integration={record} />
          <EditIntegration integration={record} />
          <DeleteIntegration integration={record} />
          <RunHistory integration={record} />
        </div>
      );
    },
  },
];

export const Integrations = () => {
  const query = useQuery<Integration[]>(INTEGRATIONS_QUERY, async () => {
    const result = await apiClient.get(`${INTEGRATIONS_API_URL}/integrations`);
    return result.data;
  });

  const [filter, setFilter] = useState({
    dataSource: '',
    sheetName: '',
    spreadsheetId: '',
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const filteredData = query.data?.filter((integration) => {
    const dataSource = integration.dataSourceType === DataSourceEnum.QUERY ? integration.alias : integration.dataSource;
    return (
      dataSource?.toLowerCase().includes(filter.dataSource.toLowerCase()) &&
      integration.sheetName.toLowerCase().includes(filter.sheetName.toLowerCase()) &&
      integration.spreadsheetId.toLowerCase().includes(filter.spreadsheetId.toLowerCase())
    );
  });

  return (
    <>
      <Row>
        <Col span={12}>
          <PageHeader title="Spreadsheet integration configuration" />
        </Col>
        <Col span={12} className="add-configuration">
          <AddIntegration />
        </Col>
      </Row>

      <div className="content-body">
        <Row gutter={16} className="filter-row" style={{ marginBottom: '1rem' }}>
          <Col span={8}>
            <Input
              placeholder="Filter by data source"
              name="dataSource"
              value={filter.dataSource}
              onChange={handleFilterChange}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Filter by sheet name"
              name="sheetName"
              value={filter.sheetName}
              onChange={handleFilterChange}
            />
          </Col>
          <Col span={8}>
            <Input
              placeholder="Filter by sheet ID"
              name="spreadsheetId"
              value={filter.spreadsheetId}
              onChange={handleFilterChange}
            />
          </Col>
        </Row>

        <Table
          loading={query.isLoading}
          dataSource={filteredData}
          columns={columns}
          rowKey="id"
          size="middle"
          pagination={false}
        />
      </div>
    </>
  );
};
