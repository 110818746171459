import { useState, useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { generateAccessToken } from 'app/repositories/api/generateAccessToken';

import { gitIntegrationSearchParams } from './consts';

export const useOAuthValidation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const provider = searchParams.get(gitIntegrationSearchParams.provider) || '';
  const code = searchParams.get(gitIntegrationSearchParams.code) || '';
  const state = searchParams.get(gitIntegrationSearchParams.state) || '';

  const repositoryProvider = provider || state;

  const {
    data,
    mutate: callGenerateAccessToken,
    isLoading: isGeneratingAccessToken,
  } = useMutation(generateAccessToken, {
    onSettled: () => {
      setSearchParams({});
      setIsLoading(false);
    },
  });

  const handleOAuth = useCallback((code: string, provider: string) => {
    setIsLoading(true);

    callGenerateAccessToken({
      code,
      provider: provider.toUpperCase(),
    });
  }, []);

  useEffect(() => {
    if (!code || !repositoryProvider) {
      setIsLoading(false);

      return;
    }

    handleOAuth(code, repositoryProvider);
  }, [repositoryProvider, code, handleOAuth]);

  const loading = isLoading || isGeneratingAccessToken;
  const isValid = !loading && !!data;

  return { oauthData: data, loading, isValid };
};
