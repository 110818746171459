import { useState, useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { generateAccessToken } from 'app/project-management/api/generateAccessToken';

import { projectIntegrationSearchParams } from './consts';

export const useOAuthValidation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const state = searchParams.get(projectIntegrationSearchParams.state) || '';
  const code = searchParams.get(projectIntegrationSearchParams.code) || '';

  const {
    data,
    mutate: callGenerateAccessToken,
    isLoading: isGeneratingAccessToken,
  } = useMutation(generateAccessToken, {
    onSettled: () => {
      setSearchParams({});
      setIsLoading(false);
    },
  });

  const handleOAuth = useCallback((code: string, state: string) => {
    setIsLoading(true);

    callGenerateAccessToken({
      code,
      provider: state.toUpperCase(),
    });
  }, []);

  useEffect(() => {
    if (!code || !state) {
      setIsLoading(false);

      return;
    }

    handleOAuth(code, state);
  }, [state, code, handleOAuth]);

  const loading = isLoading || isGeneratingAccessToken;
  const isValid = !loading && !!data;

  return { oauthData: data, loading, isValid };
};
